import React, { Component } from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import Swal from 'sweetalert2';
  
class SelfPaced extends Component{
      
    constructor(props){
        super(props);
        console.log(props.name);
        this.state = {
            first_name_self : '',
            last_name_self : '',
            user_email_self : '',
            official_email_self : '',
            company_name_self : '',
            country_code_self : '+91',
            contact_number_self : '',
            messages_self : '',
            errors: {},
            courseSelfId: this.props.courseId
        }
        this.onSubmitSelfPaced = this.onSubmitSelfPaced.bind(this)
    }

    onSubmitSelfPaced(e){

        e.preventDefault();

        let errors = {};
		let formIsValid = false;
		
		if(!this.state.first_name_self){
			formIsValid = true
			errors['first_name_self'] = "This field is required"
		}
		else if(!this.state.last_name_self){
			formIsValid = true
			errors['last_name_self'] = "This field is required"
		}
		else if(!this.state.user_email_self) {
			formIsValid = true
			errors['user_email_self'] = "This field is required"
		}
		else if(!this.state.contact_number_self){
			formIsValid = true
			errors['contact_number_self'] = "This field is required"
		}
		else if(this.state.contact_number_self.length < 10){
			formIsValid = true
			errors['contact_number_self'] = "Minimum should be 10 characters"
		}
		else if(this.state.contact_number_self.length > 10){
			formIsValid = true
			errors['contact_number_self'] = "Maximum should be 10 characters"
        }
		else if(!this.state.messages_self){
			formIsValid = true
			errors['messages_self'] = "This field is required"
		}

		if(Object.keys(errors).length > 0){
			this.setState({errors:errors})
			return formIsValid;
		}

		fetch("https://live.skillrary.com/live-courses/index.php/api/v1/business-request", {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json'
			},
			
			body: JSON.stringify({
				first_name: this.state.first_name_self,
				last_name: this.state.last_name_self,
				user_email: this.state.user_email_self,
				contact_number: this.state.country_code_self + ' '+ this.state.contact_number_self,
				message: this.state.messages_self
			})
		})
      .then(res => res.json())
      .then((result) => {
		  console.log('rest', result.status);
		  if(result.status == 200){
            Swal.fire({
            type: 'success',
            title: 'Thank you, Your request is submitted successfully',
            showConfirmButton: false,
            timer: 2500
            })
            this.setState({ first_name_self : '',last_name_self: '',user_email_self:'',contact_number_self:'',messages_self:'' })
            this.props.onCloseSelfPaced()
          }
          else if(result.status == 400){
            Swal.fire({
              type: 'error',
              title: 'Oops...',
              text: 'Something went wrong!',
              footer: '<a href>Why do I have this issue?</a>'
            })
          }
		})
	}

    render(){
        return(
            <div>
                <style jsx>
                    {`
                    
                    .inputFieldSelf{
                        font-size:15px;
                        border: 0;
                        outline: 0;
                        position: relative;
                        background: transparent;
                        border-bottom: 1px solid #33A478;
                        // -webkit-text-fill-color: black;
                        padding-left:25px;
                        padding-top:23px;
                        display: flex;
                        width: 100%;
                    }
                    .inputFieldSelfMsz{
                        font-size:15px;
                        border: 0;
                        outline: 0;
                        position: relative;
                        background: transparent;
                        border-bottom: 1px solid #33A478;
                        padding-left:5px;
                        padding-top:23px;
                        display: flex;
                        width: 100%;
                    }
                    #userLogoSelf{
                        position: absolute;
                        left: 20px;
                        top: 25px;
                        float: left;
                        color: #696969;
                    }
                    #userLogoEmailSelf{
                        position: absolute;
                        left: 20px;
                        top: 25px;
                        float: left;
                        font-size: 14px;
                        color: #696969;
                    }
                    .selfSubBtn{
                        background: #33A478;
                        border: 1px solid #33A478;
                        font-size: 16px;
                        padding: 5px 15px;
                        color: white;
                        font-weight: 600;
                        border-radius: 4px;
                    }
                    .errTextSelf{
                        font-size: 12px;
                        font-weight: normal;
                        color: red;
                        width: 300px;
                        bottom: 0;
                        left: -75px;
                        top: 49px;
                        position: absolute;
                        z-index: 1;
                    }
                    .errTextSelfText{
                        font-size: 12px;
                        font-weight: normal;
                        color: red;
                        width: 300px;
                        bottom: 0;
                        left: -75px;
                        top: 69px;
                        position: absolute;
                        z-index: 1;
                    }
                    .selfHeading{
                        text-align: center;
                        color: #33A478;
                        font-weight: 600;
                    }
                    `}
                </style>
           
                <Modal open={this.props.openSelfPaced} onClose={this.props.onCloseSelfPaced} closeOnOverlayClick={false} closeIconSize={22} styles={{ modal: {marginTop: '8%'}, overlay: {zIndex: '99999'}}}>
                <div className="modalWidth" >
                    <h4 className="selfHeading">{this.props.name}</h4>
                    <div className="container" align="center">
                        <form onSubmit={this.onSubmitSelfPaced}>

                            <div className="row">
                                <div className="col-md-6"  style={{ marginBottom: '-15px'}}>
                                    <div className="form-group">
                                        <input type="text" name="first_name" className="inputFieldSelf" 
                                        placeholder="Firstname"  autoComplete="off"
                                        value={this.state.first_name_self} onChange={(v) => this.setState({first_name_self:v.target.value,errors:{ first_name_self : false }})} />
                                        <i className="fa fa-user" id="userLogoSelf"></i>
                                        <span className="errTextSelf">{this.state.errors.first_name_self}</span>
                                    </div>
                                </div>
                                <div className="col-md-6"  style={{ marginBottom: '-15px'}}>
                                    <div className="form-group">
                                        <input type="text" name="last_name" className="inputFieldSelf" 
                                        placeholder="Lastname"  autoComplete="off"
                                        value={this.state.last_name_self} onChange={(v) => this.setState({last_name_self:v.target.value,errors:{ last_name_self : false }})} />
                                        <i className="fa fa-user" id="userLogoSelf"></i>
                                        <span className="errTextSelf">{this.state.errors.last_name_self}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12" style={{ marginBottom: '-15px'}}>
                                    <div className="form-group">
                                        <input type="email" name="user_email" className="inputFieldSelf" 
                                        placeholder="E-mail"  autoComplete="off"
                                        value={this.state.user_email_self} onChange={(v) => this.setState({user_email_self:v.target.value,errors:{ user_email_self : false }})} 
                                        />
                                        <i className="fa fa-envelope" id="userLogoEmailSelf"></i>
                                        <span className="errTextSelf">{this.state.errors.user_email_self}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12" style={{ marginBottom: '-15px'}}>
                                    <div className="form-group">
                                        <input type="email" name="official_email" className="inputFieldSelf" 
                                        placeholder="Official Mail"  autoComplete="off"
                                        value={this.state.official_email_self} onChange={(v) => this.setState({official_email_self:v.target.value,errors:{ official_email_self : false }})} 
                                        />
                                        <i className="fa fa-envelope-o" id="userLogoEmailSelf"></i>
                                        {/* <span className="errTextSelf">{this.state.errors.official_email_self}</span> */}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12" style={{ marginBottom: '-15px'}}>
                                    <div className="form-group">
                                        <input type="text" name="company_name" className="inputFieldSelf" 
                                        placeholder="Company Name"  autoComplete="off"
                                        value={this.state.company_name_self} onChange={(v) => this.setState({company_name_self:v.target.value,errors:{ company_name_self : false }})} 
                                        />
                                        <i className="fa fa-building" id="userLogoEmailSelf"></i>
                                        {/* <span className="errTextSelf">{this.state.errors.company_name_self}</span> */}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3"  style={{ marginBottom: '-15px'}}>
                                    <div className="form-group">
                                    <input type="text" name="country_code" className="inputFieldSelf" 
                                        autoComplete="off" disabled value={this.state.country_code_self}
                                         />
                                    </div>
                                </div>
                                <div className="col-md-9"  style={{ marginBottom: '-15px'}}>
                                    <div className="form-group">
                                        <input type="number" name="contact_number" className="inputFieldSelf" 
                                        placeholder="Phone"  autoComplete="off" value={this.state.contact_number_self} onChange={(v) => this.setState({contact_number_self:v.target.value,errors:{ contact_number_self : false }})} 
                                        />
                                        <i className="fa fa-phone" aria-hidden="true" id="userLogoSelf"></i>
                                        <span className="errTextSelf">{this.state.errors.contact_number_self}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row" style={{ marginBottom: '-15px'}}>
                                <div className="col-md-12"  style={{ marginBottom: '-15px'}}>
                                    <div className="form-group">
                                        <textarea type="text" name="messages" className="inputFieldSelfMsz" 
                                        placeholder="Message"  autoComplete="off" value={this.state.messages_self} onChange={(v) => this.setState({messages_self:v.target.value,errors:{ messages_self : false }})} 
                                        />
                                        <span className="errTextSelfText">{this.state.errors.messages_self}</span>
                                    </div>
                                </div>
                            </div><br/>
                        
                            <div className="row" style={{ justifyContent: "center" }}>
                                <button className="selfSubBtn">Submit</button>
                            </div>

                        </form>
                    </div>
               
		

                </div>
			    </Modal>
                </div>
         
        )
    }
}

export default SelfPaced;