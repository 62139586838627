import React, { Component } from 'react';
import sr_Logo from '../liveTrainingDesign/assets/srlogo.png';

class testimonial extends Component{
    render(){
        return(
            <div>
                <style jsx>
                    {`
                      #testimonial4 {
                        overflow: hidden;
                        min-height: 435px;
                        position: relative;
                        background: white;
                        color: black;
                      }
                      
                      #testimonial4 .carousel-inner {
                        width: 75%;
                        margin: auto;
                      }
                      
                      #testimonial4 .carousel-inner:hover {
                        cursor: -moz-grab;
                        cursor: -webkit-grab;
                      }
                      
                      #testimonial4 .carousel-inner:active {
                        cursor: -moz-grabbing;
                        cursor: -webkit-grabbing;
                      }
                      
                      #testimonial4 .carousel-inner .item {
                        overflow: hidden;
                      }
                      
                      .testimonial4_indicators .carousel-indicators {
                        left: 0;
                        margin: 0;
                        width: 100%;
                        font-size: 0;
                        height: 20px;
                        bottom: 15px;
                        padding: 0 5px;
                        cursor: e-resize;
                        overflow-x: auto;
                        overflow-y: hidden;
                        position: absolute;
                        text-align: center;
                        white-space: nowrap;
                      }
                      
                      .testimonial4_indicators .carousel-indicators li {
                        padding: 0;
                        width: 10px;
                        height: 10px;
                        border: none;
                        text-indent: 0;
                        margin: 2px 3px;
                        cursor: pointer;
                        display: inline-block;
                        background: #33A478;
                        -webkit-border-radius: 100%;
                        border-radius: 100%;
                      }
                      
                      .testimonial4_indicators .carousel-indicators .active {
                        padding: 0;
                        width: 10px;
                        height: 10px;
                        border: none;
                        margin: 2px 3px;
                        background-color: #000;
                        -webkit-border-radius: 100%;
                        border-radius: 100%;
                      }
                      
                      .testimonial4_indicators .carousel-indicators::-webkit-scrollbar {
                        height: 3px;
                      }
                      
                      .testimonial4_indicators .carousel-indicators::-webkit-scrollbar-thumb {
                        background: #eeeeee;
                        -webkit-border-radius: 0;
                        border-radius: 0;
                      }
                      
                      .testimonial4_control_button .carousel-control {
                        top: 175px;
                        opacity: 1;
                        width: 40px;
                        bottom: auto;
                        height: 40px;
                        font-size: 10px;
                        cursor: pointer;
                        font-weight: 700;
                        overflow: hidden;
                        line-height: 38px;
                        text-shadow: none;
                        text-align: center;
                        position: absolute;
                        background: transparent;
                        border: 2px solid #ffffff;
                        text-transform: uppercase;
                        -webkit-border-radius: 100%;
                        border-radius: 100%;
                        -webkit-box-shadow: none;
                        box-shadow: none;
                        -webkit-transition: all 0.6s cubic-bezier(0.3, 1, 0, 1);
                        transition: all 0.6s cubic-bezier(0.3, 1, 0, 1);
                      }
                      
                      .testimonial4_control_button .carousel-control.left {
                        left: 7%;
                        right: auto;
                        font-size: 30px;
                        color: #33A478;
                      }
                      
                      .testimonial4_control_button .carousel-control.right {
                        right: 7%;
                        left: auto;
                        font-size: 30px;
                        color: #33A478;
                      }
                      
                      .testimonial4_control_button .carousel-control.left:hover,
                      .testimonial4_control_button .carousel-control.right:hover {
                        color: #33A478;;
                        background: #fff;
                        border: 2px solid #fff;
                      }
                      
                      .testimonial4_header {
                        top: 0;
                        left: 0;
                        bottom: 0;
                        width: 100%;
                        display: block;
                        margin: 30px auto;
                        text-align: center;
                        position: relative;
                      }
                      
                      .testimonial4_header h4 {
                        color: black;
                        font-size: 30px;
                        font-weight: 600;
                        position: relative;
                        letter-spacing: 1px;
                        text-transform: uppercase;
                      }
                      
                      .testimonial4_slide {
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        width: 70%;
                        margin: auto;
                        padding: 20px;
                        position: relative;
                        text-align: center;
                      }
                      
                      .testimonial4_slide img {
                        top: 0;
                        left: 0;
                        right: 0;
                        width: 80px;
                        height: 80px;
                        margin: auto;
                        display: block;
                        color: #f2f2f2;
                        font-size: 18px;
                        text-align: center;
                        position: relative;
                        border-radius: 50px;
                      }
                      
                      .testimonial4_slide p {
                        color: black;
                        font-size: 16px;
                        margin: 40px 0 20px 0;
                      }
                      
                      .testimonial4_slide h4 {
                        color: black;
                        font-size: 24px;
                        font-weight: bold;
                      }
                      .hrBusinessdesign{
                        border: 0;
                        height: 1px;
                        background-image: linear-gradient(to right, rgba(0, 0, 0, 0), #33A478, rgba(0, 0, 0, 0));
                      }
                      @media only screen and (max-width: 480px) {
                        .testimonial4_control_button .carousel-control {
                          display: block;
                        }
                        .testimonial4_header {
                          width: 95%;
                        }
                        .testimonial4_header h4 {
                          font-size: 20px;
                        }
                        .testimonial4_slide {
                          width: 98%;
                          padding: 5px;
                        }
                        #testimonial4 {
                          overflow: hidden;
                          min-height: 523px;
                          position: relative;
                          background: white;
                          color: black;
                      }
                      }
                      
                      @media (min-width: 481px) and (max-width: 767px) {
                        .testimonial4_control_button .carousel-control.left {
                          left: 2%;
                        }
                        .testimonial4_control_button .carousel-control.right {
                          right: 2%;
                        }
                        .testimonial4_header {
                          width: 95%;
                        }
                        .testimonial4_slide {
                          width: 98%;
                          padding: 5px;
                        }
                        #testimonial4 {
                          overflow: hidden;
                          min-height: 500px;
                          position: relative;
                          background: white;
                          color: black;
                      }
                      }
                      
                      @media (min-width: 768px) and (max-width: 991px) {
                        .testimonial4_control_button .carousel-control.left {
                          left: 5%;
                        }
                        .testimonial4_control_button .carousel-control.right {
                          right: 5%;
                        }
                        #testimonial4 {
                          overflow: hidden;
                          min-height: 500px;
                          position: relative;
                          background: white;
                          color: black;
                      }
                      }
                      @media (min-width: 1024px) and (max-width: 1366px){
                        #testimonial4 {
                          overflow: hidden;
                          min-height: 500px;
                          position: relative;
                          background: white;
                          color: black;
                      }
                      }

                      @-webkit-keyframes psBoxShadowEffect_2 {
                        0% {
                          opacity: 0.3;
                        }
                        40% {
                          opacity: 0.5;
                          -webkit-box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px #ffffff, 0 0 0 10px rgba(255, 255, 255, 0.5);
                        }
                        100% {
                          -webkit-box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 5px 5px #ffffff, 0 0 0 5px rgba(255, 255, 255, 0.5);
                          -webkit-transform: scale(1.5);
                          opacity: 0;
                        }
                      }
                      
                      @keyframes psBoxShadowEffect_2 {
                        0% {
                          opacity: 0.3;
                        }
                        40% {
                          opacity: 0.5;
                          box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px #ffffff, 0 0 0 10px rgba(255, 255, 255, 0.5);
                        }
                        100% {
                          box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 5px 5px #ffffff, 0 0 0 5px rgba(255, 255, 255, 0.5);
                          transform: scale(1.5);
                          opacity: 0;
                        }
                      }
                    
                    `}
                </style>

                <div id="testimonial4" class="carousel slide testimonial4_indicators testimonial4_control_button thumb_scroll_x swipe_x" data-ride="carousel" data-pause="hover" data-interval="2000">
                  <div class="testimonial4_header">
                    <h4>Clients Testimonial's</h4>
                    <hr className="hrBusinessdesign"/>
                  </div>
                  <ol class="carousel-indicators">
                    <li data-target="#testimonial4" data-slide-to="0" class="active"></li>
                    <li data-target="#testimonial4" data-slide-to="1"></li>
                  </ol>
                  <div class="carousel-inner" role="listbox">

                    <div class="carousel-item active">
                      <div class="testimonial4_slide">
                        <img src={sr_Logo} class="img-circle img-responsive" />
                        <p>SkillRary has been one of the most effective ways of bringing our resources close to technology and learning compared to any other medium we have tried. SkillRary has been invaluable to our growth and continued success!</p>
                        <h4>Virtusa</h4>
                      </div>
                    </div>
                    <div class="carousel-item">
                      <div class="testimonial4_slide">
                        <img src={sr_Logo} class="img-circle img-responsive" />
                        <p>SkillRary completed our Testing course and has been excellent with our employees and the content that was given is simple and effective.</p>
                        <h4>Qualitest</h4>
                      </div>
                    </div>
                  </div><br/>
                  <a class="left carousel-control" href="#testimonial4" role="button" data-slide="prev">
                    <span class="fa fa-chevron-left"></span>
                  </a>
                  <a class="right carousel-control" href="#testimonial4" role="button" data-slide="next">
                    <span class="fa fa-chevron-right"></span>
                  </a>
                </div>
                </div>
        )
    }
}

export default testimonial;