import React, { Component } from 'react';

class UpSkillYourTeam extends Component{
    render(){
        return(
            <div style={{ padding: '20px' }}>
                <style jsx>
                    {`
                    [data-toggle="collapse"]:after {
                        display: inline-block;
                        font: normal normal normal 14px/1 FontAwesome;
                        font-size: inherit;
                        text-rendering: auto;
                        -webkit-font-smoothing: antialiased;
                        -moz-osx-font-smoothing: grayscale;
                        content: "\\f054";
                        transform: rotate(270deg);
                        transition: all linear 0.25s;
                        float: right;
                        position: relative;
                        margin-top: -50px;
                        right: 30px;
                    }   
                    [data-toggle="collapse"].collapsed:after {
                        transform: rotate(90deg) ;
                    }
                  
                    .upskillCardHeader{
                        padding: 0px;
                        background: inherit;
                        border-bottom: inherit;
                    }
                    .cardUpSkillMobile{
                        border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
                        -ms-flex: 1 1 auto;
                        flex: 1 1 auto;
                        padding: 1.25rem;
                        border: 1px solid #33A478;
                    }
                    .upSkillButton{
                        width: 100%;
                        background: #33A478;
                        color: black;
                        font-weight: 600;
                        padding: 8px;
                        border: 1px solid #33A478;
                    }
                    .cardText{
                        width: 80%;
                        text-align: left;
                        font-size: 14px;     
                        font-weight: 700;    
                    }
                    .subDropdown{
                        display: none;
                    }
                    .subDropdown a{
                        text-decoration: none;
                    }
                    .upSkillTeam:hover .subDropdown{
                        padding: 10px;
                        display: block;
                        z-index: 1;
                        border-radius: 5px;
                        position: absolute;
                        left: 50%;
                        transform: translate(-50%);
                        width: 90%;
                        border: 1px solid #33A478;
                        background: white;
                        box-shadow: 0 2px 10px 0 rgba(0,0,0,.1);
                    }
                    .subDropdownButton:hover {
                        color: #33A478;
                        font-weight: 600;
                        border-bottom: 2px solid #33A478;
                    }
                    .upskillHeading{
                        font-weight: 600;
                        color: black;
                    }
                    .hrBusinessdesign{
                        border: 0;
                        height: 1px;
                        background-image: linear-gradient(to right, rgba(0, 0, 0, 0), #33A478, rgba(0, 0, 0, 0));
                    }
                    .subDropdownButton{
                        // width: 100%;
                        font-size: 15px;
                        border-bottom: 1px solid white;
                        background: transparent;
                        border-top: 1px solid #33A478;
                        border-left: 1px solid #33A478;
                        border-right: 1px solid #33A478;
                        padding: 5px;
                        display: block;
                        border: 1px solid transparent;
                    }
                    .subDropdownButtonMobile{
                        // width: 100%;
                        font-size: 15px;
                        border-bottom: 1px solid white;
                        background: transparent;
                        border-top: 1px solid #33A478;
                        border-left: 1px solid #33A478;
                        border-right: 1px solid #33A478;
                        padding: 5px;
                        display: inline-block;
                        border: 1px solid transparent;
                        color: black;
                    }
                    .subDropdownButtonMobile:hover{
                        text-decoration: none;
                        color: #33A478;
                        font-weight: 600;
                        border-bottom: 2px solid #33A478;
                    }
                    .subDropdownButton:focus{
                        outline: none;
                    }
                    .course-list{
                      margin-bottom: 15px;
                    }
                    .course-list .cardText:before {
                      width: 5px;
                      height: 32px;
                      top: 10px;
                      left: 0;
                      background: #33A478;
                    }
                    .course-list .cardText:after, .course-list .cardText:before {
                      display: inline-block;
                      content: "";
                      position: absolute;
                    }
                    .course-list .cardText {
                      font-size: 15px;
                      font-weight: 600;
                      line-height: 20px;
                      letter-spacing: normal;
                      color: #272c37;
                      width: 100%;
                      height: 80px;
                      border-radius: 4px;
                      box-shadow: 0 2px 10px 0 rgba(0,0,0,.1);
                      padding-left: 20px;
                      padding-top: 10px;
                      position: relative;
                      display: inline-block;
                      text-align: left;
                      margin-bottom: 0px;
                      border: 1px solid #e6ecef;
                    }
                    .containerMobileUpskill{
                        display: none;
                    }
                    .containerDesktopUpskill{
                        display: block;
                    }
                    @media only screen and (max-width: 600px){
                        .containerMobileUpskill{
                            display: block;
                        }
                        .containerDesktopUpskill{
                            display: none;
                        }
                    }
                    @media only screen and (min-width: 768px) and  (max-width:1024px){
                        .containerMobileUpskill{
                            display: block;
                        }
                        .containerDesktopUpskill{
                            display: none;
                        }
                    }
                    `}
                </style>
                <div className="container containerDesktopUpskill" align="center">
                    <h3 className="upskillHeading">Upskill Your Team</h3>
                    <hr className="hrBusinessdesign" /><br/>
                    <div className="row">
                      <div className="col-md-3">
                        <div className="upSkillTeam course-list">
                            <p className="cardText">Programing Languages</p>
                                <div className="subDropdown">
                                    <a href="https://www.skillrary.com/" target="_blank"><button className="subDropdownButton">C</button></a>
                                    <a href="https://www.skillrary.com/" target="_blank"><button className="subDropdownButton">C++</button></a>
                                    <a href="https://www.skillrary.com/" target="_blank"><button className="subDropdownButton">Java</button></a>
                                    <a href="https://www.skillrary.com/" target="_blank"><button className="subDropdownButton">.NET</button></a>
                                    <a href="https://www.skillrary.com/" target="_blank"><button className="subDropdownButton">Python</button></a>
                                    <a href="https://www.skillrary.com/" target="_blank"><button className="subDropdownButton">SQL</button></a>
                                </div>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="upSkillTeam course-list">
                            <p className="cardText">Mobility</p>
                                <div className="subDropdown">
                                    <a href="https://www.skillrary.com/" target="_blank"><button className="subDropdownButton">Android</button></a>
                                    <a href="https://www.skillrary.com/" target="_blank"><button className="subDropdownButton">IOS</button></a>
                                    <a href="https://www.skillrary.com/" target="_blank"><button className="subDropdownButton">Mobile UI/UX</button></a>
                                    <a href="https://www.skillrary.com/" target="_blank"><button className="subDropdownButton">HTML5</button></a>
                                    <a href="https://www.skillrary.com/" target="_blank"><button className="subDropdownButton">CSS3</button></a>
                                    <a href="https://www.skillrary.com/" target="_blank"><button className="subDropdownButton">JavaScript</button></a>
                                </div>
                        </div>  
                      </div>

                      <div className="col-md-3">
                        <div className="upSkillTeam course-list">
                            <p className="cardText">IoT</p>
                                <div className="subDropdown">
                                    <a href="https://www.skillrary.com/" target="_blank"><button className="subDropdownButton">Device Drivers</button></a>
                                    <a href="https://www.skillrary.com/" target="_blank"><button className="subDropdownButton">Arduino Programming</button></a>
                                    <a href="https://www.skillrary.com/" target="_blank"><button className="subDropdownButton">Raspberry Pi</button></a>
                                    <a href="https://www.skillrary.com/" target="_blank"><button className="subDropdownButton">Data Handling</button></a>
                                    <a href="https://www.skillrary.com/" target="_blank"><button className="subDropdownButton">PLC Automation</button></a>
                                </div>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="upSkillTeam course-list">
                            <p className="cardText">Cloud</p>
                                <div className="subDropdown">
                                    <a href="https://www.skillrary.com/" target="_blank"><button className="subDropdownButton">Virtualization</button></a>
                                    <a href="https://www.skillrary.com/" target="_blank"><button className="subDropdownButton">Microsoft Azure</button></a>
                                    <a href="https://www.skillrary.com/" target="_blank"><button className="subDropdownButton">VMware Cloud</button></a>
                                    <a href="https://www.skillrary.com/" target="_blank"><button className="subDropdownButton">Salesforce</button></a>
                                    <a href="https://www.skillrary.com/" target="_blank"><button className="subDropdownButton">Amazon Web Services</button></a>
                                    <a href="https://www.skillrary.com/" target="_blank"><button className="subDropdownButton">OpenStack</button></a>
                                </div>
                        </div>  
                      </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <div className="upSkillTeam course-list">
                                <p className="cardText">Big Data</p>
                                    <div className="subDropdown">
                                        <a href="https://www.skillrary.com/" target="_blank"><button className="subDropdownButton">Hadoop</button></a>
                                        <a href="https://www.skillrary.com/" target="_blank"><button className="subDropdownButton">Map Reduce</button></a>
                                        <a href="https://www.skillrary.com/" target="_blank"><button className="subDropdownButton">Advanced MapReduce</button></a>
                                        <a href="https://www.skillrary.com/" target="_blank"><button className="subDropdownButton">Hive</button></a>
                                        <a href="https://www.skillrary.com/" target="_blank"><button className="subDropdownButton">Pig</button></a>
                                        <a href="https://www.skillrary.com/" target="_blank"><button className="subDropdownButton">HDFS</button></a>
                                        <a href="https://www.skillrary.com/" target="_blank"><button className="subDropdownButton">Splunk</button></a>
                                        <a href="https://www.skillrary.com/" target="_blank"><button className="subDropdownButton">Hbase</button></a>
                                        <a href="https://www.skillrary.com/" target="_blank"><button className="subDropdownButton">Spark</button></a>
                                        <a href="https://www.skillrary.com/" target="_blank"><button className="subDropdownButton">Sqoop</button></a>
                                        <a href="https://www.skillrary.com/" target="_blank"><button className="subDropdownButton">Flume, Ambari</button></a>
                                    </div>
                            </div>
                        </div>

                        <div className="col-md-3">
                        <div className="upSkillTeam course-list">
                            <p className="cardText">Agile and Devops</p>
                                <div className="subDropdown">
                                    <a href="https://www.skillrary.com/" target="_blank"><button className="subDropdownButton">Agile Scrum</button></a>
                                    <a href="https://www.skillrary.com/" target="_blank"><button className="subDropdownButton">CI/CD Pipeline</button></a>
                                    <a href="https://www.skillrary.com/" target="_blank"><button className="subDropdownButton">Git/GitHub</button></a>
                                    <a href="https://www.skillrary.com/" target="_blank"><button className="subDropdownButton">Jenkins</button></a>
                                    <a href="https://www.skillrary.com/" target="_blank"><button className="subDropdownButton">Maven</button></a>
                                    <a href="https://www.skillrary.com/" target="_blank"><button className="subDropdownButton">Ansible</button></a>
                                    <a href="https://www.skillrary.com/" target="_blank"><button className="subDropdownButton">Kubernetes, Docker</button></a>
                                    <a href="https://www.skillrary.com/" target="_blank"><button className="subDropdownButton">Reporting Frameworks</button></a>
                                </div>
                        </div>
                      </div>

                        <div className="col-md-3">
                            <div className="upSkillTeam course-list">
                                <p className="cardText">Software Testing</p>
                                    <div className="subDropdown">
                                        <a href="https://www.skillrary.com/" target="_blank"><button className="subDropdownButton">Manual Testing</button></a>
                                        <a href="https://www.skillrary.com/" target="_blank"><button className="subDropdownButton">Database Testing</button></a>
                                        <a href="https://www.skillrary.com/" target="_blank"><button className="subDropdownButton">Mobile Testing</button></a>
                                        <a href="https://www.skillrary.com/" target="_blank"><button className="subDropdownButton">Test Automation - Selenium, QTP, TestComplete, Katalon</button></a>
                                        <a href="https://www.skillrary.com/" target="_blank"><button className="subDropdownButton">Mobile Testing</button></a>
                                        <a href="https://www.skillrary.com/" target="_blank"><button className="subDropdownButton">Frameworks – BDD/TDD, TestNG, DDF, KDF, POM, Hybrid</button></a>
                                        <a href="https://www.skillrary.com/" target="_blank"><button className="subDropdownButton">Performance Testing – Jmeter, Load Runner, Neoload, Silk Performance Tool</button></a>
                                        <a href="https://www.skillrary.com/" target="_blank"><button className="subDropdownButton">Mobile Automation – Appium, Winium, Selendroid</button></a>
                                    </div>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="upSkillTeam course-list">
                                <p className="cardText">Development</p>
                                    <div className="subDropdown">
                                        <a href="https://www.skillrary.com/" target="_blank"><button className="subDropdownButton">Java Full Stack</button></a>
                                        <a href="https://www.skillrary.com/" target="_blank"><button className="subDropdownButton">.Net Full Stack</button></a>
                                        <a href="https://www.skillrary.com/" target="_blank"><button className="subDropdownButton">MEAN Stack</button></a>
                                        <a href="https://www.skillrary.com/" target="_blank"><button className="subDropdownButton">MERN Stack</button></a>
                                        <a href="https://www.skillrary.com/" target="_blank"><button className="subDropdownButton">Microservices</button></a>
                                        <a href="https://www.skillrary.com/" target="_blank"><button className="subDropdownButton">Big data</button></a>
                                        <a href="https://www.skillrary.com/" target="_blank"><button className="subDropdownButton">BI</button></a>
                                        <a href="https://www.skillrary.com/" target="_blank"><button className="subDropdownButton">Hadoop</button></a>
                                        <a href="https://www.skillrary.com/" target="_blank"><button className="subDropdownButton">AngularJS</button></a>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>

                 
                <div className="container containerMobileUpskill" >
                <h3 className="upskillHeading" style={{ color: 'black',textAlign:'center'}}>Upskill Your Team</h3>
                <hr className="hrBusinessdesign" /><br/>
                <div id="accordion" role="tablist">

                  <div className="upSkillTeam course-list">
                    <div data-toggle="collapse" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne" className="card-header upskillCardHeader" role="tab" id="headingOne">
                      <h5 className="mb-0">
                        <a className="cardText" style={{ color: 'black', fontWeight: '600' }}>
                        Languages
                        </a>
                      </h5>
                    </div>

                    <div id="collapseOne" className="collapse show" role="tabpanel" aria-labelledby="headingOne" data-parent="#accordion">
                      <div className="card-body cardUpSkillMobile">
                        <ul className="upskillMobileUl">
                            <li><a className="upskillMobileText subDropdownButtonMobile" href="https://www.skillrary.com/" target="_blank">C</a></li>
                            <li><a className="upskillMobileText subDropdownButtonMobile" href="https://www.skillrary.com/" target="_blank">C++</a></li>
                            <li><a className="upskillMobileText subDropdownButtonMobile" href="https://www.skillrary.com/" target="_blank">.NET</a></li>
                            <li><a className="upskillMobileText subDropdownButtonMobile" href="https://www.skillrary.com/" target="_blank">Java</a></li>
                            <li><a className="upskillMobileText subDropdownButtonMobile" href="https://www.skillrary.com/" target="_blank">Python</a></li>
                            <li><a className="upskillMobileText subDropdownButtonMobile" href="https://www.skillrary.com/" target="_blank">SQL</a></li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="upSkillTeam course-list">
                    <div data-toggle="collapse" href="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo" className="card-header upskillCardHeader collapsed" role="tab" id="headingTwo">
                      <h5 className="mb-0">
                        <a className="cardText" style={{ color: 'black', fontWeight: '600' }}>
                        Mobility
                        </a>
                      </h5>
                    </div>

                    <div id="collapseTwo" className="collapse" role="tabpanel" aria-labelledby="headingTwo" data-parent="#accordion">
                      <div className="card-body cardUpSkillMobile">
                        <ul className="upskillMobileUl">
                            <li><a  className="upskillMobileText subDropdownButtonMobile" href="https://www.skillrary.com/" target="_blank">Android</a></li>
                            <li><a  className="upskillMobileText subDropdownButtonMobile" href="https://www.skillrary.com/" target="_blank">iOS</a></li>
                            <li><a  className="upskillMobileText subDropdownButtonMobile" href="https://www.skillrary.com/" target="_blank">Mobile UI/UX</a></li>
                            <li><a  className="upskillMobileText subDropdownButtonMobile" href="https://www.skillrary.com/" target="_blank">HTML5</a></li>
                            <li><a  className="upskillMobileText subDropdownButtonMobile" href="https://www.skillrary.com/" target="_blank">CSS3</a></li>
                            <li><a  className="upskillMobileText subDropdownButtonMobile" href="https://www.skillrary.com/" target="_blank">JavaScript</a></li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="upSkillTeam course-list">
                    <div data-toggle="collapse" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree" className="card-header collapsed upskillCardHeader" role="tab" id="headingThree">
                      <h5 className="mb-0">
                        <a className="cardText" style={{ color: 'black', fontWeight: '600' }}>
                        IoT
                        </a>
                      </h5>
                    </div>

                    <div id="collapseThree" className="collapse" role="tabpanel" aria-labelledby="headingThree" data-parent="#accordion">
                        <div className="card-body cardUpSkillMobile">
                            <ul className="upskillMobileUl">
                                <li><a  className="upskillMobileText subDropdownButtonMobile"  href="https://www.skillrary.com/" target="_blank">Device Drivers</a></li>
                                <li><a  className="upskillMobileText subDropdownButtonMobile"  href="https://www.skillrary.com/" target="_blank">Arduino Programming</a></li>
                                <li><a  className="upskillMobileText subDropdownButtonMobile"  href="https://www.skillrary.com/" target="_blank">Raspberry Pi</a></li>
                                <li><a  className="upskillMobileText subDropdownButtonMobile"  href="https://www.skillrary.com/" target="_blank">Data Handling</a></li>
                                <li><a  className="upskillMobileText subDropdownButtonMobile"  href="https://www.skillrary.com/" target="_blank">PLC Automation</a></li>
                            </ul>
                        </div>
                    </div>
                  </div>


                  <div className="upSkillTeam course-list">
                    <div data-toggle="collapse" href="#collapseFour" aria-expanded="false" aria-controls="collapseFour" className="card-header collapsed upskillCardHeader" role="tab" id="headingFour">
                      <h5 className="mb-0">
                        <a className="cardText" style={{ color: 'black', fontWeight: '600' }}>
                        Cloud
                        </a>
                      </h5>
                    </div>

                    <div id="collapseFour" className="collapse" role="tabpanel" aria-labelledby="headingTwo" data-parent="#accordion">
                      <div className="card-body cardUpSkillMobile">
                        <ul className="upskillMobileUl">
                          <li><a  className="upskillMobileText  subDropdownButtonMobile" href="https://www.skillrary.com/" target="_blank">Virtualization</a></li>
                          <li><a  className="upskillMobileText  subDropdownButtonMobile" href="https://www.skillrary.com/" target="_blank">Microsoft Azure</a></li>
                          <li><a  className="upskillMobileText  subDropdownButtonMobile" href="https://www.skillrary.com/" target="_blank">VMware Cloud</a></li>
                          <li><a  className="upskillMobileText  subDropdownButtonMobile" href="https://www.skillrary.com/" target="_blank">Salesforce</a></li>
                          <li><a  className="upskillMobileText  subDropdownButtonMobile" href="https://www.skillrary.com/" target="_blank">Amazon Web Services</a></li>
                          <li><a  className="upskillMobileText  subDropdownButtonMobile" href="https://www.skillrary.com/" target="_blank">OpenStack</a></li>
                        </ul>
                      </div>
                    </div>
                  </div>


                  <div className="upSkillTeam course-list">
                    <div data-toggle="collapse" href="#collapseFive" aria-expanded="false" aria-controls="collapseFive" className="card-header collapsed upskillCardHeader" role="tab" id="headingFive">
                      <h5 className="mb-0">
                        <a className="cardText" style={{ color: 'black', fontWeight: '600' }}>
                        Big Data
                        </a>
                      </h5>
                    </div>

                    <div id="collapseFive" className="collapse" role="tabpanel" aria-labelledby="headingFive" data-parent="#accordion">
                      <div className="card-body cardUpSkillMobile">
                        <ul className="upskillMobileUl">
                          <li><a  className="upskillMobileText subDropdownButtonMobile" href="https://www.skillrary.com/" target="_blank">Map Reduce</a></li>
                          <li><a  className="upskillMobileText subDropdownButtonMobile" href="https://www.skillrary.com/" target="_blank">Advanced MapReduce</a></li>
                          <li><a  className="upskillMobileText subDropdownButtonMobile" href="https://www.skillrary.com/" target="_blank">Hive</a></li>
                          <li><a  className="upskillMobileText subDropdownButtonMobile" href="https://www.skillrary.com/" target="_blank"> Pig</a></li>
                          <li><a  className="upskillMobileText subDropdownButtonMobile" href="https://www.skillrary.com/" target="_blank">HDFS</a></li>
                          <li><a  className="upskillMobileText subDropdownButtonMobile" href="https://www.skillrary.com/" target="_blank">Hadoop</a></li>
                          <li><a  className="upskillMobileText subDropdownButtonMobile" href="https://www.skillrary.com/" target="_blank">Splunk</a></li>
                          <li><a  className="upskillMobileText subDropdownButtonMobile" href="https://www.skillrary.com/" target="_blank">Hbase</a></li>
                          <li><a  className="upskillMobileText subDropdownButtonMobile" href="https://www.skillrary.com/" target="_blank">Spark</a></li>
                        </ul>
                      </div>
                    </div>
                  </div>



                  <div className="upSkillTeam course-list">
                    <div data-toggle="collapse" href="#collapseSix" aria-expanded="false" aria-controls="collapseSix" className="card-header collapsed upskillCardHeader" role="tab" id="headingSix">
                      <h5 className="mb-0">
                        <a className="cardText" style={{ color: 'black', fontWeight: '600' }}>
                        Agile and Devops
                        </a>
                      </h5>
                    </div>

                    <div id="collapseSix" className="collapse" role="tabpanel" aria-labelledby="headingSix" data-parent="#accordion">
                      <div className="card-body cardUpSkillMobile">
                        <ul className="upskillMobileUl">
                          <li><a className="upskillMobileText subDropdownButtonMobile" href="https://www.skillrary.com/" target="_blank">Agile Scrum</a></li>
                          <li><a className="upskillMobileText subDropdownButtonMobile" href="https://www.skillrary.com/" target="_blank">CI/CD Pipeline</a></li>
                          <li><a className="upskillMobileText subDropdownButtonMobile" href="https://www.skillrary.com/" target="_blank">Git/GitHub</a></li>
                          <li><a className="upskillMobileText subDropdownButtonMobile" href="https://www.skillrary.com/" target="_blank">Jenkins</a></li>
                          <li><a className="upskillMobileText subDropdownButtonMobile" href="https://www.skillrary.com/" target="_blank"> Maven</a></li>
                          <li><a className="upskillMobileText subDropdownButtonMobile" href="https://www.skillrary.com/" target="_blank"> Ansible</a></li>
                          <li><a className="upskillMobileText subDropdownButtonMobile" href="https://www.skillrary.com/" target="_blank"> Kubernetes, Docker</a></li>
                          <li><a className="upskillMobileText subDropdownButtonMobile" href="https://www.skillrary.com/" target="_blank">  Reporting Frameworks</a></li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="upSkillTeam course-list">
                    <div data-toggle="collapse" href="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven" className="card-header collapsed upskillCardHeader" role="tab" id="headingSeven">
                      <h5 className="mb-0">
                        <a className="cardText" style={{ color: 'black', fontWeight: '600' }}>
                        Software Testing
                        </a>
                      </h5>
                    </div>

                    <div id="collapseSeven" className="collapse" role="tabpanel" aria-labelledby="headingSeven" data-parent="#accordion">
                      <div className="card-body cardUpSkillMobile">
                        <ul className="upskillMobileUl">
                          <li><a className="upskillMobileText subDropdownButtonMobile" href="https://www.skillrary.com/" target="_blank">Manual Testing</a></li>
                          <li><a className="upskillMobileText subDropdownButtonMobile" href="https://www.skillrary.com/" target="_blank">Database Testing</a></li>
                          <li><a className="upskillMobileText subDropdownButtonMobile" href="https://www.skillrary.com/" target="_blank">Mobile Testing</a></li>
                          <li><a className="upskillMobileText subDropdownButtonMobile" href="https://www.skillrary.com/" target="_blank">Test Automation - Selenium, QTP, TestComplete, Katalon</a></li>
                          <li><a className="upskillMobileText subDropdownButtonMobile" href="https://www.skillrary.com/" target="_blank">Mobile Testing</a></li>
                          <li><a className="upskillMobileText subDropdownButtonMobile" href="https://www.skillrary.com/" target="_blank">Frameworks – BDD/TDD, TestNG, DDF, KDF, POM, Hybrid</a></li>
                          <li><a className="upskillMobileText subDropdownButtonMobile" href="https://www.skillrary.com/" target="_blank">Performance Testing – Jmeter, Load Runner, Neoload, Silk Performance Tool</a></li>
                          <li><a className="upskillMobileText subDropdownButtonMobile" href="https://www.skillrary.com/" target="_blank">Mobile Automation – Appium, Winium, Selendroid</a></li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="upSkillTeam course-list">
                    <div data-toggle="collapse" href="#collapseNine" aria-expanded="false" aria-controls="collapseNine" className="card-header collapsed upskillCardHeader" role="tab" id="headingNine">
                      <h5 className="mb-0">
                        <a className="cardText" style={{ color: 'black', fontWeight: '600' }}>
                        Development
                        </a>
                      </h5>
                    </div>

                    <div id="collapseNine" className="collapse" role="tabpanel" aria-labelledby="headingNine" data-parent="#accordion">
                      <div className="card-body cardUpSkillMobile">
                        <ul className="upskillMobileUl">
                          <li><a className="upskillMobileText subDropdownButtonMobile" href="https://www.skillrary.com/" target="_blank">Java Full Stack</a></li>
                          <li><a className="upskillMobileText subDropdownButtonMobile" href="https://www.skillrary.com/" target="_blank">.Net Full Stack</a></li>
                          <li><a className="upskillMobileText subDropdownButtonMobile" href="https://www.skillrary.com/" target="_blank">MEAN Stack</a></li>
                          <li><a className="upskillMobileText subDropdownButtonMobile" href="https://www.skillrary.com/" target="_blank">MERN Stack</a></li>
                          <li><a className="upskillMobileText subDropdownButtonMobile" href="https://www.skillrary.com/" target="_blank">Microservices</a></li>
                          <li><a className="upskillMobileText subDropdownButtonMobile" href="https://www.skillrary.com/" target="_blank">Big data</a></li>
                          <li><a className="upskillMobileText subDropdownButtonMobile" href="https://www.skillrary.com/" target="_blank">BI</a></li>
                          <li><a className="upskillMobileText subDropdownButtonMobile" href="https://www.skillrary.com/" target="_blank">Hadoop</a></li>
                          <li><a className="upskillMobileText subDropdownButtonMobile" href="https://www.skillrary.com/" target="_blank">AngularJS</a></li>
                        </ul>
                      </div>
                    </div>
                  </div>

                </div>
                </div>
            
            </div>
        )
    }
}

export default UpSkillYourTeam;