import React, { Component } from 'react';
// import bannerOurMethodsImage from '../../../src/components/liveTrainingDesign/assets/home-banner-3.jpg';
// import learningOurImage from '../../components/liveTrainingDesign/assets/startlearning.png';
import SelfPaced from '../../components/businessPage/selfPacedForm';
import Instructor from '../../components/businessPage/instructorForm';
import ClassroomTraining from '../../components/businessPage/classroomTrainingForm';
import classroom from '../../components/liveTrainingDesign/assets/businesspage/classroom.png';
import online from '../../components/liveTrainingDesign/assets/businesspage/online1.png';
import selfPaced from '../../components/liveTrainingDesign/assets/businesspage/online.png';
import ourTraining from '../../components/liveTrainingDesign/assets/businesspage/Training-Method.jpg';

class OurTrainingMethods extends Component{

    constructor(props){
        super(props);
        this.state = {
            openSelfPaced1 : false,
            openSelfPaced2 : false,
            openSelfPaced3 : false,
            Self: 'Self Paced Training',
            Instructor: 'Instructor Training',
            ClassRoom: 'Classroom Training'
            // openInstructor: false,
            // openClassTraining: false
        }
        this.onOpenSelfPaced = this.onOpenSelfPaced.bind(this);
    }

    onOpenSelfPaced(a){
        console.log('aa',a);
        console.log(typeof(a))
        if(a === 1){
            console.log(typeof(a))
            console.log('aa===== inside',a);
            this.setState({ openSelfPaced1: true });
        }
        else if(a === 2){
            console.log(typeof(a))
            console.log('aa===== inside',a);
            this.setState({ openSelfPaced2: true });
        }
        else{
            console.log(typeof(a))
            console.log('aa===== inside',a);
            this.setState({ openSelfPaced3: true });
        }
	};
	 
	onCloseSelfPaced = (a) => {
        if(a === 1){
            console.log(typeof(a))
            console.log('aa===== inside',a);
            this.setState({ openSelfPaced1: false });
        }
        else if(a === 2){
            console.log(typeof(a))
            console.log('aa===== inside',a);
            this.setState({ openSelfPaced2: false });
        }
        else{
            console.log(typeof(a))
            console.log('aa===== inside',a);
            this.setState({ openSelfPaced3: false });
        }
    };
    // onOpenInstructor = () => {
	// 	this.setState({ openInstructor: true });
	// };
	 
	// onCloseInstructor = () => {
	// 	this.setState({ openInstructor: false });
	// };
    // onOpenClassTraining = () => {
	// 	this.setState({ openClassTraining: true });
	// };
	 
	// onCloseClassTraining = () => {
	// 	this.setState({ openClassTraining: false });
	// };

	render(){
		return(
			<div>
				<style jsx>{`
                    #bannerOurContainer{
                        padding: 0px;
                        position: relative;
                    }
                    .bannerContentOurContainer{
                        position: absolute;
                        width: 100%;
                        top: 50%;
                        left: 0px;
                        padding: 1rem;
                        transform: translateY(-50%);
                        color: white;
                    }
                    .bannerOurMethodContent h3{
                        width: 23%;
                        background: white;
                        font-weight: 600;
                        color: black;
                        line-height: 100px;
                        opacity: 0.7;
                        height: 110px;
                        font-size: 31px;
                    }
                    .ourTrainingMethodCards{
                        background: white;
                        padding: 8px;
                        border: 2px solid black;
                        border-radius: 7px;
                        color: black;
                        height: 365px;
                        font-size: 15px;
                    }
                    .ourTrainingLogo{
                        height: 70px;
                        width: 100%;
                        max-width: 82px;
                    }
                    .requestOurBtn{
                        background: #33A478;
                        border: 1px solid #33A478;
                        padding: 8px 15px;
                        border-radius: 5px;
                        font-weight: 600;
                        margin-bottom: 15px;
                        color: black;
                        font-size: 18px;
                    }
                    .requestOurBtn:focus{
                        outline: none;
                    }
                    #ourTrainingMobile{
                        display: none;
                    }


                    // .zoom {
                    //     overflow: hidden;
                    //     animation: scale 40s linear infinite;
                    // }
                        
                    // @keyframes scale {
                    //     50% {
                    //         -webkit-transform:scale(1.2);
                    //         -moz-transform:scale(1.2);
                    //         -ms-transform:scale(1.2);
                    //         -o-transform:scale(1.2);
                    //         transform:scale(1.2);
                    //         transform-origin: 50% 50%;
                    //     }
                    // }


                    @keyframes zoomEffect {
                        from {transform: scale(1, 1)}
                         to {transform: scale(1.5, 1.5)}
                    }
                    
                    .zoom {
                        width: 100%;
                        margin: 0px;
                        padding: 0px;
                        display: inline-block;
                        border: 1px solid transparent;
                        overflow: hidden; 
                    }

                    .zoom img {
                        display: block; 
                        animation: 20s ease 0s normal none infinite running zoomEffect;
                        -webkit-animation: 20s ease 0s normal none infinite running zoomEffect;
                        -o-animation: 20s ease 0s normal none infinite running zoomEffect;
                        -moz--o-animation: 20s ease 0s normal none infinite running zoomEffect;
                    }

                    @media only screen and (max-width: 600px) {
                        .ourTrainingMethodCards {
                            background: white;
                            padding: 8px;
                            border: 2px solid black;
                            border-radius: 7px;
                            color: black;
                            height: auto;
                        }
                        .ourBannerIMage{
                            display: none;
                        }		
                        .bannerContentOurContainer{
                            position: absolute;
                            width: 100%;
                            top: 50%;
                            left: 0px;
                            padding: 1rem;
                            transform: translateY(-50%);
                            color: white;
                        }		
                        #bannerOurContainer{
                            display: none;
                        }
                        #ourTrainingMobile{
                            display: block;
                        }
                    }
                    @media only screen and (min-width: 768px) and (max-width: 1023px){
                        #bannerOurContainer{
                            display: none;
                        }
                        #ourTrainingMobile{
                            display: block;
                        }
                        .ourTrainingMethodCards{
                            background: white;
                            padding: 8px;
                            border: 2px solid black;
                            border-radius: 7px;
                            color: black;
                            height: 600px;
                        }
                    }
                    @media only screen and (min-width:1024px) and (max-width:1280px){
                        #bannerOurContainer{
                            display: none;
                        }
                        #ourTrainingMobile{
                            display: block;
                        }
                        .ourTrainingMethodCards{
                            background: white;
                            padding: 8px;
                            border: 2px solid black;
                            border-radius: 7px;
                            color: black;
                            height: 600px;
                        }
                    }
					`}
				</style>
			<div className="container-fluid" id="bannerOurContainer">
                <div className="zoom">
				    <img src={ourTraining} alt="banner" width="100%" /> 
                </div>
				 <div className="bannerContentOurContainer" align="center">
					<div className="bannerOurMethodContent">
						<h3>Our Training Methods</h3><br />
                        <div className="row" style={{ padding: '0px', margin: '0px'}}>
                            <div className="col-md-4">
                                <div className="card ourTrainingMethodCards">
                                    <div align="center">
                                    <img src={selfPaced} className="ourTrainingLogo" align="center" /><br/>
                                    </div>
                                        <h5 style={{ color: '#33A478',fontWeight:'600',marginTop: "10px" }}>Self Paced Training</h5><br/>
                                        <ul style={{ textAlign:'left'}}>
                                            <li style={{ lineHeight: '22px' }}>High Quality Content designed by the experts of SkillRary</li>
                                            <li style={{ lineHeight: '22px' }}>Free Assessment can be attended after completion of the course</li>
                                            <li style={{ lineHeight: '22px' }}>Grooming Session will be provided to clarify doubts</li>
                                            <li style={{ lineHeight: '22px' }}>Hands on Learning</li>
                                            <li style={{ lineHeight: '21px' }}>Skill Analytics</li>
                                            <li style={{ lineHeight: '22px' }}>Course Materials</li>
                                            <li style={{ lineHeight: '21px' }}>Certificate on Completion</li>
                                        </ul>
                                </div><br/>
                                <button className="requestOurBtn" onClick={() => this.onOpenSelfPaced(1)} >Request</button>
                                <div>
                                    <SelfPaced openSelfPaced={this.state.openSelfPaced1} name={this.state.Self} courseId={this.state.courseId} onCloseSelfPaced={() => this.onCloseSelfPaced(1)}/>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card ourTrainingMethodCards">
                                    <div align="center">
                                    <img src={online} className="ourTrainingLogo" align="center" /><br/>
                                    </div>
                                        <h5 style={{ color: '#33A478',fontWeight:'600',marginTop: "10px" }}>Instructor Led Live Training</h5><br/>
                                        <ul style={{ textAlign:'left'}}>
                                            <li style={{ lineHeight: '22px' }}>Live Training will be provided to group of employees with presentation and real time example</li>
                                            <li style={{ lineHeight: '22px' }}>Training will be conducted based on the schedule</li>
                                            <li style={{ lineHeight: '21px' }}>Recordings of each session will be provided</li>
                                            <li style={{ lineHeight: '21px' }}>Live Projects</li>
                                            <li style={{ lineHeight: '22px' }}>Delivered through Goto meeting</li>
                                            <li style={{ lineHeight: '21px' }}>Skill Analytics of each employee</li>
                                            <li style={{ lineHeight: '21px' }}>Customisable Course Content</li>
                                            <li style={{ lineHeight: '21px' }}>Certificate</li>
                                        </ul>
                                </div><br/>
                                <button className="requestOurBtn" onClick={() => this.onOpenSelfPaced(2)}>Request</button>
                                <div>
                                    <SelfPaced openSelfPaced={this.state.openSelfPaced2} name={this.state.Instructor} courseId={this.state.courseId} onCloseSelfPaced={() => this.onCloseSelfPaced(2)}/>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card ourTrainingMethodCards">
                                    <div align="center">
                                        <img src={classroom} className="ourTrainingLogo" align="center" /><br/>
                                    </div>
                                        <h5 style={{ color: '#33A478',fontWeight:'600',marginTop: "10px" }}>Classroom Training</h5><br/>
                                        <ul style={{ textAlign:'left'}}>
                                            <li>Face to Face Training</li>
                                            <li>Conducted based on the schedule</li>
                                            <li>Training will be conducted at your/our venue</li>
                                            <li>Customisable Course content</li>
                                            <li>Free Access to Assessments</li>
                                            <li>Hands on learning</li>
                                            <li>Course Materials</li>
                                            <li>Certificate</li>
                                        </ul>
                                </div><br/>
                                <button className="requestOurBtn" onClick={() => this.onOpenSelfPaced(3)}>Request</button>
                                <div>
                                    <SelfPaced openSelfPaced={this.state.openSelfPaced3} name={this.state.ClassRoom} courseId={this.state.courseId} onCloseSelfPaced={() => this.onCloseSelfPaced(3)}/>
                                </div>
                            </div>
                        </div>
					</div>
				</div>
			</div>			

            <div className="container" id="ourTrainingMobile">
                <div align="center">
                    <div>
                        <h3>Our Training Methods</h3><br />
                        <div className="row" style={{ padding: '0px', margin: '0px'}}>
                            <div className="col-md-4">
                                <div className="card ourTrainingMethodCards">
                                    <div align="center">
                                        <img src={selfPaced} className="ourTrainingLogo" align="center" /><br/>
                                    </div>
                                        <h5 style={{ color: '#33A478',fontWeight:'600',marginTop: "10px" }}>Self Paced Training</h5><br/>
                                        <ul style={{ textAlign:'left'}}>
                                            <li style={{ lineHeight: '22px' }}>High Quality Content designed by the experts of SkillRary</li>
                                            <li style={{ lineHeight: '22px' }}>Free Assessment can be attended after completion of the course</li>
                                            <li style={{ lineHeight: '22px' }}>Grooming Session will be provided to clarify doubts</li>
                                            <li style={{ lineHeight: '22px' }}>Hands on Learning</li>
                                            <li style={{ lineHeight: '21px' }}>Skill Analytics</li>
                                            <li style={{ lineHeight: '22px' }}>Course Materials</li>
                                            <li style={{ lineHeight: '21px' }}>Certificate on Completion</li>
                                        </ul> 
                                </div><br/>
                                <button className="requestOurBtn" onClick={() => this.onOpenSelfPaced(1)}>Request</button>
                                <div>
                                    <SelfPaced openSelfPaced={this.state.openSelfPaced1} name={this.state.Self} courseId={this.state.courseId} onCloseSelfPaced={() => this.onCloseSelfPaced(1)}/>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card ourTrainingMethodCards">
                                    <div align="center">
                                        <img src={online} className="ourTrainingLogo" align="center" /><br/>
                                    </div>
                                        <h5 style={{ color: '#33A478',fontWeight:'600',marginTop: "10px" }}>Instructor Led Live Training</h5><br/>
                                        <ul style={{ textAlign:'left'}}>
                                            <li style={{ lineHeight: '22px' }}>Live Training will be provided to group of employees with presentation and real time example</li>
                                            <li style={{ lineHeight: '22px' }}>Training will be conducted based on the schedule</li>
                                            <li style={{ lineHeight: '21px' }}>Recordings of each session will be provided</li>
                                            <li style={{ lineHeight: '21px' }}>Live Projects</li>
                                            <li style={{ lineHeight: '22px' }}>Delivered through Goto meeting</li>
                                            <li style={{ lineHeight: '21px' }}>Skill Analytics of each employee</li>
                                            <li style={{ lineHeight: '21px' }}>Customisable Course Content</li>
                                            <li style={{ lineHeight: '21px' }}>Certificate</li>
                                        </ul>
                                </div><br/>
                                {/* <button className="requestOurBtn" onClick={this.onOpenInstructor}>Request</button> */}
                                <button className="requestOurBtn" onClick={() => this.onOpenSelfPaced(2)}>Request</button>
                                <div>
                                    <SelfPaced openSelfPaced={this.state.openSelfPaced2} name={this.state.Instructor} courseId={this.state.courseId} onCloseSelfPaced={() => this.onCloseSelfPaced(2)}/>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card ourTrainingMethodCards">
                                    <div align="center">
                                        <img src={classroom} className="ourTrainingLogo" align="center" /><br/>
                                    </div>
                                            <h5 style={{ color: '#33A478',fontWeight:'600',marginTop: "10px" }}>Classroom Training</h5><br/>
                                            <ul style={{ textAlign:'left'}}>
                                                <li>Face to Face Training</li>
                                                <li>Conducted based on the schedule</li>
                                                <li>Training will be conducted at your/our venue</li>
                                                <li>Customisable Course content</li>
                                                <li>Free Access to Assessments</li>
                                                <li>Hands on learning</li>
                                                <li>Course Materials</li>
                                                <li>Certificate</li>
                                            </ul>
                                </div><br/>
                                <button className="requestOurBtn"onClick={() => this.onOpenSelfPaced(3)}>Request</button>
                                {/* <button className="requestOurBtn" onClick={this.onOpenClassTraining}>Request</button> */}
                                <div>
                                    <SelfPaced openSelfPaced={this.state.openSelfPaced3} name={this.state.ClassRoom} courseId={this.state.courseId} onCloseSelfPaced={() => this.onCloseSelfPaced(3)}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="testimonials"></div>
			</div>
		)
	}
}

export default OurTrainingMethods;