import React, { Component, Fragment }from 'react';
import './App.css';
import FooterTraining from "../src/components/liveTrainingDesign/footerTraining.jsx";
// import BusinessPageComponent from '../src/components/businessPage/businessPage';
import NeverStopLearning from '../src/components/businessPage/neverStopLearning';
import UpSkillYourTeam from '../src/components/businessPage/upSkillYourTeam';
import OurTrainingMethods from '../src/components/businessPage/ourTrainingMethods';
import Testimonial from '../src/components/businessPage/testimonialCarousel';
import BusinessBannerImage from './components/businessPage/businessBannerImage';

class App extends Component {
    constructor(props) {
      super(props);       
    }
    render() {
        return (
            <div className="App">
                <BusinessBannerImage /><br/><br/>
                <NeverStopLearning /><br/>
                <UpSkillYourTeam /><br/><br/>
                <OurTrainingMethods /><br/>
                <Testimonial /><br/>
                <FooterTraining />
            </div>
        );  
    }
 
}
export default App;

