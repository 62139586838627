import React, { Component } from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

class Instructor extends Component{
    constructor(props){
        super(props);
        
        console.log('Instructor===================', props)
        this.onSubmitInstructor = this.onSubmitInstructor.bind(this)
    }

    onSubmitInstructor(e){
        e.preventDefault();
    }
    
    render(){
        return(
            <div>
                <style jsx>
                    {`
                    path {
                        fill: black;
                    }
                    .inputFieldInstructor{
                        font-size:15px;
                        border: 0;
                        outline: 0;
                        position: relative;
                        background: transparent;
                        border-bottom: 1px solid #33A478;
                        // -webkit-text-fill-color: black;
                        padding-left:25px;
                        padding-top:23px;
                        display: flex;
                        width: 100%;
                    }
                    .inputFieldInstructorMsz{
                        font-size:15px;
                        border: 0;
                        outline: 0;
                        position: relative;
                        background: transparent;
                        border-bottom: 1px solid #33A478;
                        padding-left:5px;
                        padding-top:23px;
                        display: flex;
                        width: 100%;
                    }
                    #userLogoInstructor{
                        position: absolute;
                        left: 20px;
                        top: 25px;
                        float: left;
                        color: #696969;
                    }
                    #userLogoEmailInstructor{
                        position: absolute;
                        left: 20px;
                        top: 25px;
                        float: left;
                        font-size: 14px;
                        color: #696969;
                    }
                    .InstructorSubBtn{
                        background: #33A478;
                        border: 1px solid #33A478;
                        font-size: 16px;
                        padding: 5px 15px;
                        color: white;
                        font-weight: 600;
                        border-radius: 4px;
                    }
                    .instructorHeading{
                        text-align: center;
                        color: #33A478;
                        font-weight: 600;
                    }
                    `}
                </style>
                <Modal open={this.props.openInstructor}  onClose={this.props.onCloseInstructor} closeOnOverlayClick={false} closeIconSize={22} styles={{ modal: { marginTop: '8%' }}}>
                <div className="modalWidth">
                    <h4 className="instructorHeading">Instructor Led Live Training</h4>
                    <form onSubmit={this.onSubmitInstructor}>
                        <div className="row">
                            <div className="col-md-6"  style={{ marginBottom: '-15px'}}>
                                <div className="form-group">
                                    <input type="text" name="first_name" className="inputFieldInstructor" 
                                    placeholder="Firstname"  autoComplete="off"
                                    />
                                    <i className="fa fa-user" id="userLogoInstructor"></i>
                                </div>
                            </div>
                            <div className="col-md-6"  style={{ marginBottom: '-15px'}}>
                                <div className="form-group">
                                    <input type="text" name="last_name" className="inputFieldInstructor" 
                                    placeholder="Lastname"  autoComplete="off"
                                    />
                                    <i className="fa fa-user" id="userLogoInstructor"></i>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12" style={{ marginBottom: '-15px'}}>
                                <div className="form-group">
                                    <input type="email" name="user_email" className="inputFieldInstructor" 
                                    placeholder="E-mail"  autoComplete="off"
                                        
                                    />
                                    <i className="fa fa-envelope" id="userLogoEmailInstructor"></i>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3"  style={{ marginBottom: '-15px'}}>
                                <div className="form-group">
                                <input type="text" name="country_code" className="inputFieldInstructor" 
                                    autoComplete="off" disabled value="+91"
                                        />
                                </div>
                            </div>
                            <div className="col-md-9"  style={{ marginBottom: '-15px'}}>
                                <div className="form-group">
                                    <input type="number" name="contact_number" className="inputFieldInstructor" 
                                    placeholder="Phone"  autoComplete="off"  
                                    />
                                    <i className="fa fa-phone" aria-hidden="true" id="userLogoInstructor"></i>
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{ marginBottom: '-15px'}}>
                            <div className="col-md-12"  style={{ marginBottom: '-15px'}}>
                                <div className="form-group">
                                    <textarea type="text" name="messages" className="inputFieldInstructorMsz" 
                                    placeholder="Message"  autoComplete="off" 
                                    />
                                </div>
                            </div>
                        </div><br/>
                    
                        <div className="row" style={{ justifyContent: "center" }}>
                            <button className="InstructorSubBtn">Submit</button>
                        </div>
                    </form>
                </div>
			    </Modal>
            </div>
        )
    }
}

export default Instructor;