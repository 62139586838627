import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import $ from 'jquery';
import bannerBusinessImage from '../../../src/components/liveTrainingDesign/assets/businesspage/image5.jpg';
import lecture from '../../components/liveTrainingDesign/assets/businesspage/lecture.png';
import diploma from '../../components/liveTrainingDesign/assets/businesspage/web-development.png';
import shopping from '../../components/liveTrainingDesign/assets/businesspage/shopping1.png';
import platform from '../../components/liveTrainingDesign/assets/businesspage/platform.png';
import skillrary_logo from '../../components/liveTrainingDesign/assets/skillrary_logo.jpg';
import corporateTraining from '../../components/liveTrainingDesign/assets/businesspage/Corporate-Training.jpg';
import companyBranding from '../../components/liveTrainingDesign/assets/businesspage/Company-Branding.jpg';
import supportMaintainance from '../../components/liveTrainingDesign/assets/businesspage/Support.jpg';
import platformCloning from '../../components/liveTrainingDesign/assets/businesspage/Platform-Cloning.jpg';
import anime from 'animejs/lib/anime.es.js';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import Swal from 'sweetalert2';

class businessBannerImage extends Component{

    constructor(props){
        super(props);
        this.state ={
            openContact : false,
            // full_name: '',
            // emails: '',
            // subject: '',
            // messages: '',
            first_name: '',
            last_name: '',
            corporate_name: '',
            user_email: '',
            contact_number: '',
            message: '',
            country_code: '+91',
            errors: {}
        }
        this.onopenContact = this.onopenContact.bind(this)
        this.onSubmitContact = this.onSubmitContact.bind(this)
        this.onCloseContact = this.onCloseContact.bind(this)
    }

    onopenContact = () => {
        this.setState({ openContact : true })
    } 
    
    onCloseContact = () => {
        this.setState({ openContact : false })
        this.setState({ first_name : '',last_name: '',corporate_name:'',user_email:'',contact_number: '',message: '',openContact: false  })
    }

    componentDidMount(){
        $(window).scroll(function() {
            if($(this).scrollTop() == 0){
                $('#navList').css('background','transparent');
                $('.businessList ul li').css('margin-top','40px');
                $('.businessList ul li img').css('display','none');
            }
            else{
                $('#navList').css('background','#33A478');
                $('.businessList ul li').css('margin-top','0px');
                $('.businessList ul li img').css('margin-top','-106px');
                $('.businessList ul li img').css('display','inline-block');
            }
        });


        // Wrap every letter in a span
        var textWrapper = document.querySelector('.businessHeaderBanner');
        textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, "<span class='letter'>$&</span>");

        anime.timeline({loop: true})
        .add({
            targets: '.businessHeaderBanner .letter',
            scale: [4,1],
            opacity: [0,1],
            translateZ: 0,
            easing: "easeOutExpo",
            duration: 950,
            delay: (el, i) => 70*i
        }).add({
            targets: '.businessHeaderBanner',
            opacity: 0,
            duration: 1000,
            easing: "easeOutExpo",
            delay: 1000
        });
    }

    openNav = () => {
        $('#myNav').css('height','100%');
    }

    closeNav = () => {
        $('#myNav').css('height','0%');
    }

    onSubmitContact(e){
    //     e.preventDefault();
    //     let errors = {};
	// 	let formIsValid = false;
	// 	if(!this.state.full_name){
    //         formIsValid = true
    //         errors['full_name'] = "This field is required"
	// 	}
	// 	else if(!this.state.emails){
	// 		formIsValid = true
	// 		errors['emails'] = "This field is required"
	// 	}
	// 	else if(!this.state.subject) {
	// 		formIsValid = true
	// 		errors['subject'] = "This field is required"
	// 	}
	// 	else if(!this.state.messages){
	// 		formIsValid = true
	// 		errors['messages'] = "This field is required"
	// 	}
	
	// 	if(Object.keys(errors).length > 0){
	// 		this.setState({errors:errors})
	// 		return formIsValid;
	// 	}

	// 	fetch("https://live.skillrary.com/live-courses/index.php/api/v1/business-contact", {
	// 		method: 'POST',
	// 		headers: {
	// 			'Content-Type': 'application/json',
	// 			'Accept': 'application/json'
	// 		},
			
	// 		body: JSON.stringify({
    //             full_name: this.state.full_name,
    //             emails: this.state.emails,
    //             subject: this.state.subject,
    //             message: this.state.messages
	// 		})
	// 	})
    //   .then(res => res.json())
    //   .then((result) => {
	// 	  console.log('rest', result.status);
	// 	  if(result.status == 200){
    //         Swal.fire({
    //         type: 'success',
    //         title: 'Thank you, Your request is submitted successfully',
    //         showConfirmButton: false,
    //         timer: 2500
    //         })
    //         this.setState({ full_name : '',emails: '',subject:'',messages:'' })
    //         this.onCloseContact()
    //       }
    //       else if(result.status == 400){
    //         Swal.fire({
    //           type: 'error',
    //           title: 'Oops...',
    //           text: 'Something went wrong!',
    //           footer: '<a href>Why do I have this issue?</a>'
    //         })
    //       }
    // 	})
    
    e.preventDefault();

		var course_quote_id = this.props.courseId

		let errors = {};
		let formIsValid = false;

		if(!this.state.first_name){
			formIsValid = true;
			errors['first_name'] = "This field is required"
		}
		else if(!this.state.last_name){
			formIsValid = true;
			errors['last_name'] = "This field is required"
		}
		else if(!this.state.corporate_name){
			formIsValid = true;
			errors['corporate_name'] = "This field is required"
		}
		else if(!this.state.user_email){
			formIsValid = true;
			errors['user_email'] = "This field is required"
		}
		else if(!this.state.contact_number){
			formIsValid = true
			errors['contact_number'] = "This field is required"
		}
		else if(this.state.contact_number.length < 10){
			formIsValid = true
			errors['contact_number'] = "Minimum should be 10 characters"
		}
		else if(this.state.contact_number.length > 10){
			formIsValid = true
			errors['contact_number'] = "Maximum should be 10 characters"
		}
		else if(!this.state.message){
			formIsValid = true;
			errors['message'] = "This is field is required"
		}
		if(Object.keys(errors).length > 0 ){
			this.setState({errors:errors})
			return formIsValid;
        }
        
        // var result =  JSON.stringify({
        //     course_id: window.sessionStorage.getItem('courseId'),
        //     first_name: this.state.first_name,
        //     last_name: this.state.last_name,
        //     corporate_name: this.state.corporate_name,
        //     user_email: this.state.user_email,
        //     contact_number: this.state.country_code + ' ' + this.state.contact_number,
        //     message: this.state.message
        // })
        // console.log(result);
        // return;

		fetch("https://live.skillrary.com/live-courses/index.php/api/v1/corporate-request", {
			method: 'POST',

			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json'
			},
			body: JSON.stringify({
				course_id: 1,
				first_name: this.state.first_name,
				last_name: this.state.last_name,
				corporate_name: this.state.corporate_name,
				user_email: this.state.user_email,
				contact_number: this.state.country_code + ' ' + this.state.contact_number,
				message: this.state.message
			})
		})
      .then(res => res.json())
      .then((result) => {
		  console.log('rest', result.status);
		  if(result.status == 200){
            Swal.fire({
            type: 'success',
            title: 'Thank you, Your request is submitted successfully',
            showConfirmButton: false,
            timer: 2500
            })
			this.setState({first_name : '',last_name: '',corporate_name:'',user_email:'',contact_number:'',message:'',openContact: false })
          }
          else if(result.status == 400){
            Swal.fire({
              type: 'error',
              title: 'Oops...',
              text: 'Something went wrong!',
              footer: '<a href>Why do I have this issue?</a>'
            })
          }
		})
		
    }

    render(){
        const { openContact } = this.state
        return(
            <div>
                <style jsx>
                    {`
                    .errText{
                        font-size: 13px;
                        left: 15px;
                        position: absolute;
                        color: red;
                    }
                    .sessionSubBtn{
                        background: #33A478;
                        border: 1px solid #33A478;
                        font-size: 16px;
                        padding: 5px 15px;
                        color: white;
                        font-weight: 600;
                        border-radius: 4px;
                    }
                    #userLogo{
                        position: absolute;
                        left: 20px;
                        top: 25px;
                        float: left;
                        color: #696969;
                    }
                    #userLogoEmail{
                        position: absolute;
                        left: 20px;
                        top: 28px;
                        float: left;
                        font-size: 14px;
                        color: #696969;
                    }
                    .errTextContact{
                        font-size: 12px;
                        font-weight: normal;
                        color: red;
                        width: 300px;
                        bottom: 0;
                        left: -75px;
                        top: 49px;
                        position: absolute;
                        z-index: 1;
                    }
                    .contactSubmitBtn{
                        background: #33A478;
                        padding: 7px 20px;
                        font-weight: 700;
                        border: 1px solid #33A478;
                        border-radius: 5px;
                    }
                    .contactSubmitBtn:focus{
                        outline: none;
                    }
                    textarea{
                        resize: none;
                    }
                    .modalWidth{
                        width: 600px !important;
                        margin-top: 50px;
                    }
                    .inputField{
                        font-size:15px;
                        border: 0;
                        outline: 0;
                        position: relative;
                        background: transparent;
                        border-bottom: 1px solid #33A478;
                        // -webkit-text-fill-color: black;
                        padding-left:23px;
                        padding-top:23px;
                        display: flex;
                        width: 100%;
                    }
                    .inputFieldMsz{
                        font-size:15px;
                        border: 0;
                        outline: 0;
                        position: relative;
                        background: transparent;
                        border-bottom: 1px solid #33A478;
                        padding-left:5px;
                        padding-top:23px;
                        display: flex;
                        width: 100%;
                    }
                    .query{
                        background: #33A478;
                        position: fixed;
                        font-size: 20px;
                        top: 40%;
                        padding: 19px;
                        right: -69px;
                        letter-spacing: 3px;
                        font-size: 25px;
                        font-weight: 600;
                        border: 1px solid #33A478;
                        /* margin: 0px; */
                        transform: rotate(-90deg);
                        -webkit-transform: rotate(90deg);
                        z-index: 9999;
                    }
                    .overlay a {
                        padding: 17px !important;
                        text-decoration: none;
                        font-size: 20px;
                        color: white;
                        display: inline-block;
                        transition: 0.3s;
                    }
                    .businessHeaderBanner {
                        font-weight: 900;
                        font-size: 4.5em;
                    }
                    
                    .businessHeaderBanner .letter {
                        display: inline-block;
                        line-height: 1em;
                    }

                    .logo_business{
                        height: 52px;
                        position: relative; 
                        line-height: 103px;
                        margin-top: -11px;
                    
                    }
                    .searchButtonBusiness {
                        float: left;
                        background: transparent;
                        color: black;
                        font-size: 17px;
                        border: 1px solid transparent;
                        border-radius: 0px 4px 4px 0px;
                        border-left: none;
                        cursor: pointer;
                        /* margin-top: 3px; */
                        height: 28px;
                        position: absolute;
                        /* color: white; */
                        margin-left: -108px;
                        display: block;
                        z-index: 0;
                    }
                    .searchButtonBusiness:focus{
                        outline: none;
                    }

                    .dropdownBusiness .dropbtnBusiness {
                        font-size: 20px;  
                        border: none;
                        outline: none;
                        color: white;
                        padding: 0px 9px;
                        background-color: inherit;
                        font-family: inherit;
                        margin: 0;
                    }
                    .dropdown-contentBusiness {
                        display: none;
                        position: absolute;
                        background-color: #f9f9f9;
                        min-width: 160px;
                        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
                        z-index: 1;
                    }
        
                    .dropdown-contentBusiness a {
                        color: black;
                        padding: 12px 16px;
                        text-decoration: none;
                        display: block;
                        text-align: left;
                    }
        
                    .dropdownBusiness:hover .dropdown-contentBusiness {
                        display: block;
                    }
                    
                    .businessHeader{
                        width: 100%;
                        height: 700px;
                    }
                    .businessList{
                        height: 80px;
                        width: 100%;
                        padding: 8px;
                        position: fixed;
                        top: 0px;
                        transition: .5s;
                        // text-align: center;
                    }
                    .businessList ul li{
                        list-style-type: none;
                        display: inline-block;
                        padding: 6px 20px;
                        color: #33A478;
                        font-size: 24px;
                        cursor: pointer;
                        margin-left: 80px;
                        margin-top: 40px;
                    }
                    .businessList ul{
                        margin-bottom: 0px !important;
                    }

                    .overlay {
                        height: 0%;
                        width: 100%;
                        position: fixed;
                        z-index: 9999;
                        top: 0;
                        left: 0;
                        background-color: #000000c7;
                        overflow-y: hidden;
                        transition: 0.5s;
                    }
                    
                    .overlay-content {
                        position: relative;
                        top: 25%;
                        width: 17%;
                        padding: 5px;
                        background: black;
                        text-align: center;
                        margin-top: 30px;
                        left: 42%;
                        transform: translate(0%,0%);
                    }
                    
                    .overlay a {
                        padding: 8px;
                        text-decoration: none;
                        font-size: 20px;
                        color: white;
                        display: inline-block;
                        transition: 0.3s;
                    }
                    
                    .overlay a:hover, .overlay a:focus {
                        color: #f1f1f1;
                    }
                    
                    .overlay .closebtn {
                        position: absolute;
                        top: 20px;
                        right: 45px;
                        font-size: 60px;
                        color: white !important;
                        cursor: pointer;
                    }
                    .contactBtn{
                        background: #33A478;
                        border: 1px solid #33A478;
                        font-weight: 600;
                        padding: 10px;
                        // width: 15%;
                        font-size: 25px;
                    }
                    .contactBtn:focus{
                        outline: none;
                    }
                    .businessBannerContainer{
                        padding: 0px;
                        margin: 0px;
                        position: relative;
                    }
                    .businessBannerImage{
                        width: 100%;
                        height: 700px;
                        position: relative;
                    }
                    .bannerBusinessContent{
                        position: absolute;
                        top: 30%;
                        left: 5%;
                        color: white;
                    }
                    .businessCard{
                        background: white;
                        width: 100%;
                        padding: 8px;
                        border-radius: initial;
                        border: 1px solid transparent;
                    }
                    .imageBusiness{
                        width: 100%;
                        background: white;
                        height: 120px;
                        padding: 8px;
                    }
                    .cardRowBusiness{
                        position: relative;
                    }
                    .textonBanner{
                        color: #FFFFFF;
                        text-shadow: 3px 5px 2px #474747;
                        width: 53%;
                        font-size: 25px;
                    }
                    .hexagon{
                        clip-path: polygon(75% 0, 100% 51%, 76% 100%, 25% 100%, 0% 50%, 25% 0%);
                        -webkit-clip-path: polygon(75% 0, 100% 51%, 76% 100%, 25% 100%, 0% 50%, 25% 0%);
                    }
                    .cardContainerBusiness{
                        padding: 0;
                    }

                    .businessCardGreen{
                        border-bottom: 1px solid #ddd;
                        transition-duration: .5s;
                    }
                    .horizontalLineGreen{
                        transition-duration: .5s;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 6px;
                        z-index: -1;
                    }
                    .circleGreen{
                        transition-duration: .5s;
                        border: 1px solid #33A478;
                        width: 100px;
                        background: white;
                        height: 100px;
                        border-radius: 50px;
                        top: -50px;
                        position: absolute;
                        /* margin: 0 auto; */
                        left: 50%;
                        left: 50%;
                        transform: translate(-50%,0);
                    }
                    .bookiconGreen{
                        color: #33A478;
                        font-size:30px;
                        line-height:95px; 
                        vertical-align: middle;
                    }
                    .customContentGreen{
                        display: none;
                        transition-duration: .5s;
                    }
                    .imageCustomGreen{
                        transition-duration: .5s;
                        width: 100%;
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        display: none;
                        border-right: 2px solid black;
                        border-top: 2px solid black;
                        border-left: 2px solid black;
                    }
                    .businessCardGreen:hover{
                        border: 2px solid black;
                        border-radius: 15px;
                        box-shadow: 0 3px 10px 2px rgba(0,0,0,.2);
                    }
                    .businessCardGreen:hover .customHeadingGreen{
                        color: #33A478;
                        font-weight: 700;
                        font-size: 20px;
                    }
                    .businessCardGreen:hover .customContentGreen{
                        left: 0px;
                        border-left: 2px solid black;
                        border-right: 2px solid black;
                        display:block;
                        padding: 60px 10px 0px;
                        top: -158px;
                        position: absolute;
                        background-color: #fff;
                        transition-duration: .5s;
                        font-size: 15px;
                    }
                    .businessCardGreen:hover .horizontalLineGreen{
                        top: -158px;
                        z-index: 1;
                        position: absolute;
                    }
                    .businessCardGreen:hover .circleGreen{
                        top: -205px;
                        z-index: 1;
                    }
                    .businessCardGreen:hover .imageCustomGreen{
                        width: 100%;
                        position: absolute;
                        top: -313px;
                        left: 0px;
                        display: block;
                        transition-duration: .5s;
                        border-top-right-radius: 15px;
                        border-top-left-radius: 15px;
                    }
                    .customHeadingGreen{ 
                        padding: 115px 25px 10px 25px;
                    }
                    .circleBlack{
                        border: 1px solid black;
                        width: 100px;
                        background: white;
                        height: 100px;
                        border-radius: 50px;
                        top: -50px;
                        position: absolute;
                        /* margin: 0 auto; */
                        left: 50%;
                        left: 50%;
                        transform: translate(-50%,0);
                        transition-duration: .5s;
                    }
                    .bookiconBlack{
                        color: black;
                        font-size: 30px;
                        /* line-height: 108px; */
                        width: 100%;
                        max-width: 46px;
                        min-width: 55px;
                        /* vertical-align: middle; */
                        height: 55px;
                        margin-top: 20px;
                    }
                    .businessCardBlack{
                        border-bottom: 1px solid #ddd;
                        transition-duration: .5s;
                    }
                    .horizontalLineBlack{
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 6px;
                        z-index: -1;
                        transition-duration: .5s;
                    }
                    .customContentBlack{
                        display: none;
                        transition-duration: .5s;   
                    }

                    .imageCustomBlack{
                        transition-duration: .5s;
                        width: 100%;
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        display: none;
                        border-right: 2px solid black;
                        border-top: 2px solid black;
                        border-left: 2px solid black;
                    }
                    .customHeadingBlack{ 
                        padding: 115px 25px 10px 25px;
                    }

                    .businessCardBlack:hover{
                        border-radius: 15px;
                        border: 2px solid black;
                        box-shadow: 0 3px 10px 2px rgba(0,0,0,.2);
                    }
                    .businessCardBlack:hover .customHeadingBlack{
                        color: #33A478;
                        font-weight: 700;
                        font-size: 20px;
                    }
                    .businessCardBlack:hover .customContentBlack{
                        transition-duration: .5s;
                        left: 0px;
                        border-left: 2px solid black;
                        border-right: 2px solid black;
                        display:block;
                        padding: 60px 10px 0px;
                        top: -158px;
                        position: absolute;
                        background-color: #fff;
                        font-size: 15px;
                    }
                    .businessCardBlack:hover .horizontalLineBlack{
                        top: -158px;
                        z-index: 1;
                        position: absolute;
                    }
                    .businessCardBlack:hover .circleBlack{
                        top: -205px;
                        z-index: 1;
                    }
                    .businessCardBlack:hover .imageCustomBlack{
                        transition-duration: .5s;
                        width: 100%;
                        position: absolute;
                        top: -313px;
                        left: 0px;
                        display: block;
                        border-top-right-radius: 15px;
                        border-top-left-radius: 15px;
                    }
                    .containerBusinessDesktop{
                        display: block;
                        height: 175px;
                    }
                    .containerBusinessMobile{
                        display: none;
                    }
                    .businessHeaderBannerMobile{
                        display:none;
                    }
                    @media only screen and (min-width: 400px) and (max-width : 600px){
                        .modalWidth {
                            width: 300px !important;
                        }
                    }
                    @media only screen and (max-width: 600px){
                        .modalWidth {
                            width: 300px !important;
                        }
                        .overlay-content {
                            position: relative;
                            top: 25%;
                            width: inherit;
                            text-align: center;
                            margin-top: 30px;
                            left: 0%;
                            transform: translate(0%,0%);
                        }
                        .businessHeaderBannerMobile{
                            display:block;
                        }
                        .businessHeaderBanner {
                            font-weight: 900;
                            font-size: 35px;
                            display: none;
                        }
                        
                        .businessList ul li{
                            list-style-type: none;
                            display: inline-block;
                            padding: 6px 20px;
                            color: #33A478;
                            font-size: 24px;
                            cursor: pointer;
                            margin-left: 0px;
                            margin-top: 40px;
                        }
                        .textonBanner{
                            width: 53%;
                            font-size: 15px;
                        }
                        .containerBusinessDesktop{
                            display: none;
                        }
                        .containerBusinessMobile{
                            display: block;
                        }
                        .cardRowBusiness{
                            margin-top: 30px;
                        }
                        .businessCardGreenM{
                            border-bottom: 1px solid #ddd;
                        }
                        .horizontalLineGreenM{
                            position: relative;
                            margin-top: 53.5%;
                            left: 0;
                            width: 100%;
                            height: 6px;
                            z-index: -1;
                        }
                        .circleGreenM{
                            border: 1px solid #33A478;
                            width: 100px;
                            background: white;
                            height: 100px;
                            border-radius: 50px;
                            position: absolute;
                            // top: 50%;
                            left: 50%;
                            transform: translate(-50%,-50%);
                        }
                        .bookiconGreenM{
                            color: #33A478;
                            font-size:30px;
                            line-height:95px; 
                            vertical-align: middle;
                        }
                        .customContentGreenM{
                            display: block;
                            margin-top: 65px;
                        }
                        .imageCustomGreenM{
                            width: 100%;
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            display: block;
                        }
                        .customHeadingGreenM{ 
                            padding: 0px;
                        }
                        .circleBlackM{
                            border: 1px solid black;
                            width: 100px;
                            background: white;
                            height: 100px;
                            border-radius: 50px;
                            position: absolute;
                            // top: 50%;
                            left: 50%;
                            transform: translate(-50%,-50%);
                        }
                        .bookiconBlackM{
                            color: black;
                            font-size:30px;
                            line-height:95px; 
                            vertical-align: middle;
                        }
                        .businessCardBlackM{
                            border-bottom: 1px solid #ddd;
                        }
                        .horizontalLineBlackM{
                            position: relative;
                            margin-top: 53.5%;
                            left: 0;
                            width: 100%;
                            height: 6px;
                            z-index: -1;
                        }
                        .customContentBlackM{
                            display: block;
                            margin-top: 65px;
                        }
                        .imageCustomBlackM{
                            width: 100%;
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            display: block;
                        }
                        .customHeadingBlackM{ 
                            padding: 0px;
                        }
                    }
                    @media only screen and (min-width: 768px) and  (max-width:1024px){
                        .overlay-content {
                            position: relative;
                            top: 25%;
                            width: inherit;
                            text-align: center;
                            margin-top: 30px;
                            left: 0%;
                            transform: translate(0%,0%);
                        }
                        .businessCardBlack:hover .imageCustomBlack {
                            transition-duration: .5s;
                            width: 100%;
                            /* height: 136px; */
                            position: absolute;
                            top: -255px;
                            left: 0px;
                            display: block;
                            border-top-right-radius: 15px;
                            border-top-left-radius: 15px;
                        }
                        .containerBusinessDesktop {
                            display: block;
                            height: 230px;
                        }
                        .businessHeaderBannerMobile{
                            display:none;
                        }
                        .businessCardGreen:hover .imageCustomGreen {
                            width: 100%;
                            position: absolute;
                            top: -255px;
                            left: 0px;
                            display: block;
    
                            border-top-right-radius: 15px;
                            border-top-left-radius: 15px;
                        }
                        .fontinIpad{
                            font-size: 13px;
                        }
                        .textonBanner{
                            width: 53%;
                            font-size: 15px;
                        }
                        .containerBusinessDesktop{
                            display: block;
                        }
                        .containerBusinessMobile{
                            display: none;
                        }
                        .customHeadingGreen {
                            padding: 160px 18px 25px 25px;
                        }
                        .businessCardGreen:hover .customContentGreen{
                            display: block;
                            padding: 80px 0px 0px;
                            top: -158px;
                            position: absolute;
                            background-color: #fff;
            
                            
                        }
                        .customHeadingBlack {
                            padding: 160px 18px 25px 25px;
                        }
                        .businessCardBlack:hover .customContentBlack{
                            display: block;
                            padding: 80px 0px 0px;
                            top: -158px;
                            position: absolute;
                            background-color: #fff;
                   
                        }
                    }
                    @media only screen and (min-width:1024px) and (max-width:1280px){
                        .containerBusinessDesktop {
                            display: block;
                            height: 220px;
                        }
                        .businessHeaderBannerMobile{
                            display:none;
                        }
                        .containerBusinessDesktop{
                            display: block;
                        }
                        .containerBusinessMobile{
                            display: none;
                        }
                        .customHeadingBlack {
                            padding: 165px 25px 25px 25px;
                        }
                        .customHeadingGreen{ 
                            padding: 165px 25px 25px 25px;
                        }
                        .bannerBusinessContent {
                            position: absolute;
                            top: 25%;
                            left: 5%;
                            color: white;
                        }
                    }
                   
                      @media screen and (max-height: 450px) {
                        .overlay {overflow-y: auto;}
                        .overlay a {font-size: 20px}
                        .overlay .closebtn {
                            font-size: 40px;
                            top: 15px;
                            right: 35px;
                        }
                      }

                    `}
                </style>
                    <div className="container-fluid businessBannerContainer businessHeader">
                    <img src={bannerBusinessImage} className="businessBannerImage"/>
                    <div className="businessList" id="navList" style={{ zIndex: '9999'}}>
                        <ul style={{ padding: '0px' }}>
                            <li><span style={{ fontSize: '30px',cursor:'pointer', background: 'black', padding: '10px 20px' }} onClick={this.openNav}>&#9776;</span> </li>
                            <li style={{ display:'block',marginTop: '-48px',textAlign: 'center',marginLeft: '0px'}}><img src={skillrary_logo} className="logo_business" style={{ display: 'none' }}/></li>
                        </ul>
                       
                    </div>
                    <div className="bannerBusinessContainer">
                        <div className="bannerBusinessContent">

                             {/* mobile */}

                            <h1 className="businessHeaderBannerMobile" style={{ color: 'white', fontWeight: '600' }}>ONWARDS & UPWARDS</h1><br />
                            
                            {/* desktop */}

                            <h1 className="businessHeaderBanner" style={{ color: 'white', fontWeight: '600' }}>ONWARDS & UPWARDS</h1><br />
                            <p className="textonBanner">Advance your skills in tech, design, business and much more... Our experts are fully fortified to upskill your workface</p>    
                            <button className="contactBtn" onClick={this.onopenContact}>Contact Us</button>
                        </div>
                    </div>
                </div>
                <Modal open={openContact} onClose={this.onCloseContact} closeOnOverlayClick={false} closeIconSize={22} styles={{ modal: { marginTop: '10%' }}}>
                    <div className="modalWidth" >
                    <h3 className="sessionHeading" style={{ marginTop: '-50px',textAlign: 'center' }}>Contact Us</h3>
                        {/* <p style={{ fontSize: '13px', textAlign: 'center' }}>Please fill in the details and Skillrary Course Advisor will get back within 24 hrs to help you up skill your team</p> */}
                        <div className="container" align="center">
                            {/* <form onSubmit={this.onSubmitContact}>

                                <div className="row">
                                    <div className="col-md-6 offset-md-3"  style={{ marginBottom: '-15px'}}>
                                        <div className="form-group">
                                            <input type="text" name="full_name" className="inputField" 
                                            placeholder="Full name"  autoComplete="off"
                                            value={this.state.full_name} onChange={(v) => this.setState({full_name:v.target.value})}
                                           />
                                            <span className="errTextContact">{this.state.errors.full_name}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 offset-md-3" style={{ marginBottom: '-15px'}}>
                                        <div className="form-group">
                                            <input type="email" name="emails" className="inputField" 
                                            placeholder="Email"  autoComplete="off"                                            
                                            value={this.state.emails} onChange={(v) => this.setState({emails:v.target.value})}
                                            />
                                            <span className="errTextContact">{this.state.errors.emails}</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6 offset-md-3" style={{ marginBottom: '-15px'}}>
                                        <div className="form-group">
                                            <input type="text" name="subject" className="inputField" 
                                            placeholder="Subject"  autoComplete="off"
                                            value={this.state.subject} onChange={(v) => this.setState({subject:v.target.value})}
                                            />
                                           <span className="errTextContact">{this.state.errors.subject}</span>
                                        </div>
                                    </div>
                                </div>
                              
                                <div className="row" style={{ marginBottom: '-15px'}}>
                                    <div className="col-md-6 offset-md-3"  style={{ marginBottom: '-15px'}}>
                                        <div className="form-group">
                                            <textarea type="text" name="messages" className="inputFieldMsz" 
                                            placeholder="Message"  autoComplete="off"
                                            value={this.state.messages} onChange={(v) => this.setState({messages:v.target.value})}
                                             />
                                            <span className="errTextContact">{this.state.errors.messages}</span>
                                        </div>
                                    </div>
                                </div><br/><br/>
                            
                                <div className="row" style={{ justifyContent: "center" }}>
                                    <button type="submit" className="contactSubmitBtn">Submit</button>
                                </div>
                            </form> */}
                            <form onSubmit={this.onSubmitContact}>
                                <div className="row">
                                    <div className="col-md-6"  style={{ marginBottom: '-15px'}}>
                                        <div className="form-group">
                                            <input type="text" name="first_name" 
                                            className="inputField" placeholder="Firstname" autoComplete="off"
                                            onChange={(v) => this.setState({first_name:v.target.value, errors: {first_name: false }})} value={this.state.first_name} />
                                            <i className="fa fa-user" id="userLogo"></i>
                                            <span className="errText">{this.state.errors.first_name}</span>
                                        </div>
                                    </div>
                                    <div className="col-md-6"  style={{ marginBottom: '-15px'}}>
                                        <div className="form-group">
                                            <input type="text" name="last_name" 
                                            className="inputField" placeholder="Lastname" autoComplete="off"
                                            onChange={(v) => this.setState({last_name:v.target.value, errors: {last_name: false }})} value={this.state.last_name} />
                                            <i className="fa fa-user" id="userLogo"></i>
                                            <span className="errText">{this.state.errors.last_name}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12" style={{ marginBottom: '-15px'}}>
                                        <div className="form-group">
                                            <input type="text" name="corporate_name" 
                                            className="inputField" placeholder="Company" autoComplete="off"
                                            onChange={(v) => this.setState({corporate_name:v.target.value, errors: { corporate_name : false}})} value={this.state.corporate_name} />
                                            <i className="fa fa-building-o" id="userLogoEmail"></i>
                                            <span className="errText">{this.state.errors.corporate_name}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12" style={{ marginBottom: '-15px'}}>
                                        <div className="form-group">
                                            <input type="email" name="user_email"
                                                className="inputField" placeholder="E-mail" autoComplete="off"
                                                onChange={(v) => this.setState({user_email:v.target.value, errors: {user_email : false }})} value={this.state.user_email} />
                                            <i className="fa fa-envelope" id="userLogoEmail"></i>
                                            <span className="errText">{this.state.errors.user_email}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3" style={{ marginBottom: '-15px'}}>
                                        <div className="form-group">
                                        <input type="text" name="country_code" className="inputField"  readOnly
                                            autoComplete="off"
                                            value={this.state.country_code} onChange={(v) => this.setState({country_code: v.target.value})}/>
                                        </div>
                                    </div>
                                    <div className="col-md-9"  style={{ marginBottom: '-15px'}}>
                                        <div className="form-group">
                                            <input type="number" name="contact_number"
                                                className="inputField" placeholder="Phone" autoComplete="off"
                                                value={this.state.contact_number} 
                                                onChange={(v) => this.setState({contact_number: v.target.value, errors: {contact_number : false }})} />
                                            <i className="fa fa-phone" aria-hidden="true" id="userLogo"></i>
                                            <span className="errText">{this.state.errors.contact_number}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="row" style={{ marginBottom: '-15px'}}>
                                    <div className="col-md-12"  style={{ marginBottom: '-15px'}}>
                                        <div className="form-group">
                                            <textarea type="text" name="message" 
                                            className="inputFieldMsz" placeholder="Message" autoComplete="off"
                                            value={this.state.message} onChange={(v) => this.setState({message: v.target.value, errors: { message: false }})} />
                                            <span className="errTextArea">{this.state.errors.message}</span>
                                        </div>
                                    </div>
                                </div><br/>
                            
                                <div className="row" style={{ justifyContent: "center" }}>
                                    <button className="sessionSubBtn">Submit</button>
                                </div>
                                <p style={{ fontSize: '13px', textAlign: 'center', marginTop: '8px' }}>* At Skillrary, we take data security very seriously. Hence, we will ensure that the information mentioned by you will be used only to solve your query.</p>
                            </form>
                        </div>
                    </div>
                </Modal> 

                <div className="container containerBusinessDesktop" id="never">
                    <div className="row cardRowBusiness">
                        <div className="col-md-3 cardContainerBusiness" align="center">
                            <div className="businessCardGreen">
                                <div>
                                    <img src={corporateTraining} className="imageCustomGreen" />
                                </div>
                                <div className="horizontalLineGreen" style={{ backgroundColor: '#33A478'}}>

                                </div>
                                <div className="circleGreen">
                                    <img src={lecture} alt="lecture" className="bookiconBlack"/>
                                </div>
                                <div>
                                    <p className="customContentGreen">You will have unlimited access of all the subscribed courses which you can access anytime anywhere. These courses will be available only for up skilling your workforce as they are crafted based on your requests. We also customise assessments, polls, MCQ’s etc. for your courses.</p>
                                    <p className="customHeadingGreen">Corporate Training</p>
                                </div>
                            </div> 
                        </div>

                        <div className="col-md-3 cardContainerBusiness" align="center">
                            <div className="businessCardBlack">
                                <div>
                                    <img src={platformCloning} className="imageCustomBlack" />
                                </div>
                                <div className="horizontalLineBlack" style={{ backgroundColor: 'black'}}>

                                </div>
                                <div className="circleBlack">
                                    <img src={diploma} alt="lecture" className="bookiconBlack"/>
                                </div>
                                <div>
                                    <p className="customContentBlack">We develop a platform similar to  SkillRary.com, where you can create and upload your own courses. Also you can get unlimited access to 500+ courses of SkillRary which you have subscribed for on your platform.</p>
                                    <p className="customHeadingBlack">Platform Cloning</p>
                                </div>
                            </div>
                        </div>  

                        <div className="col-md-3 cardContainerBusiness" align="center">
                            <div className="businessCardGreen">
                                <div>
                                    <img src={companyBranding} className="imageCustomGreen" />
                                </div>
                                <div className="horizontalLineGreen" style={{ backgroundColor: '#33A478'}}>

                                </div>
                                <div className="circleGreen">
                                    <img src={platform} alt="lecture" className="bookiconBlack"/>
                                </div>
                                <div>
                                    <p className="customContentGreen">We help you in branding not just the specific products or services; we bring in holistic practice of promoting the entire brand name of your corporate entity.</p>
                                    <p className="customHeadingGreen">Company Branding</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3 cardContainerBusiness" align="center">
                            <div className="businessCardBlack">
                                <div>
                                    <img src={supportMaintainance} className="imageCustomBlack" />
                                </div>
                                <div className="horizontalLineBlack" style={{ backgroundColor: 'black'}}>

                                </div>
                                <div className="circleBlack">
                                    <img src={shopping} alt="lecture" className="bookiconBlack"/>
                                </div>
                                <div>
                                    <p className="customContentBlack">A website that is customised based on your requirements will be supported and maintained by SkillRary for an initial period of 3 months at no extra cost; after which a maintenance fee will be charged for the same service.</p>
                                    <p className="customHeadingBlack fontinIpad">Support & Maintenance</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="container containerBusinessMobile">
                    <div className="row cardRowBusiness">
                        <div className="col-md-3 cardContainerBusiness" align="center">
                            <div className="businessCardGreenM">
                                <div>
                                    <img src={corporateTraining} className="imageCustomGreenM" />
                                </div>
                                <div className="horizontalLineGreenM" style={{ backgroundColor: '#33A478'}}>

                                </div>
                                <div className="circleGreenM">
                                    <img src={lecture} alt="lecture" className="bookiconBlack"/>
                                </div>
                                <div>
                                    <p className="customContentGreenM">You will have unlimited access of all the subscribed courses which you can access anytime anywhere. These courses will be available only for up skilling your workforce as they are crafted based on your requests. We also customise assessments, polls, MCQ’s etc. for your courses.</p>
                                    <p className="customHeadingGreenM">Corporate Training</p>
                                </div>
                            </div> 
                        </div>
                        <div className="col-md-3 cardContainerBusiness" align="center">
                            <div className="businessCardBlackM">
                                <div>
                                    <img src={platformCloning} className="imageCustomBlackM" />
                                </div>
                                <div className="horizontalLineBlackM" style={{ backgroundColor: 'black'}}>

                                </div>
                                <div className="circleBlackM">
                                    <img src={diploma} alt="lecture" className="bookiconBlack"/>
                                </div>
                                <div>
                                    <p className="customContentBlackM">We develop a platform similar to  SkillRary.com, where you can create and upload your own courses. Also you can get unlimited access to 500+ courses of SkillRary which you have subscribed for on your platform.</p>
                                    <p className="customHeadingBlackM">Platform Cloning</p>
                                </div>
                            </div>
                        </div>  
                        <div className="col-md-3 cardContainerBusiness" align="center">
                            <div className="businessCardGreenM">
                                <div>
                                    <img src={companyBranding} className="imageCustomGreenM" />
                                </div>
                                <div className="horizontalLineGreenM" style={{ backgroundColor: '#33A478'}}>

                                </div>
                                <div className="circleGreenM">
                                    <img src={platform} alt="lecture" className="bookiconBlack"/>
                                </div>
                                <div>
                                    <p className="customContentGreenM">We help you in branding not just the specific products or services; we bring in holistic practice of promoting the entire brand name of your corporate entity.</p>
                                    <p className="customHeadingGreenM">Company Branding</p>
                                </div>
                            </div> 
                        </div>
                        <div className="col-md-3 cardContainerBusiness" align="center">
                            <div className="businessCardBlackM">
                                <div>
                                    <img src={supportMaintainance} className="imageCustomBlackM" />
                                </div>
                                <div className="horizontalLineBlackM" style={{ backgroundColor: 'black'}}>

                                </div>
                                <div className="circleBlackM">
                                    <img src={shopping} alt="lecture" className="bookiconBlack"/>
                                </div>
                                <div>
                                    <p className="customContentBlackM">A website that is customised based on your requirements will be supported and maintained by SkillRary for an initial period of 3 months at no extra cost; after which a maintenance fee will be charged for the same service.</p>
                                    <p className="customHeadingBlackM">Support & Maintenance</p>
                                </div>
                            </div>
                        </div>  
                    </div>
                </div>
                    <div id="myNav" className="overlay">
                        <a className="closebtn" onClick={this.closeNav}>&times;</a>
                        <div className="overlay-content">
                            <NavLink to="/" onClick={this.closeNav} exact activeClassName="activeHeading"  className="nav-link nav-link-header">Home</NavLink>
                            <a href='#never' onClick={this.closeNav} activeClassName="activeHeading"  className="nav-link nav-link-header">Never Stop Learning</a>
                            <a href="#bannerOurContainer" onClick={this.closeNav} activeClassName="activeHeading"  className="nav-link nav-link-header">Our Training Methods</a>
                            {/* <NavLink to="/howItWorks"  activeClassName="activeHeading"  className="nav-link nav-link-header">Up Skill Your Team</NavLink> */}
                            <a href="#testimonials" onClick={this.closeNav} activeClassName="activeHeading"  className="nav-link nav-link-header">Clients Testimonial's</a>
                        </div>
                    </div>
            </div>
        )
    }
}

export default businessBannerImage;