import React, { Component } from 'react';
import BusinessServiceImage from '../../../src/components/liveTrainingDesign/assets/home-banner-3.jpg';
import seotraining from '../../components/liveTrainingDesign/assets/businesspage/seo-training.png';
import online from '../../components/liveTrainingDesign/assets/businesspage/web-design.png';
import analytics from '../../components/liveTrainingDesign/assets/businesspage/ai.png';
import livetraining from '../../components/liveTrainingDesign/assets/businesspage/liveTraining.png';
import server from '../../components/liveTrainingDesign/assets/businesspage/server.png';
import development from '../../components/liveTrainingDesign/assets/businesspage/development1.png';
import taskList from '../../components/liveTrainingDesign/assets/businesspage/taskList.png';
import neverstopLearning from '../../components/liveTrainingDesign/assets/businesspage/Never-Stop-Learning.jpg'

class neverStopLearning extends Component{
    render(){
        return(
            <div>
                <style jsx>
                    {`
                    .neverStopHeading{
                        font-weight: 600;
                    }
                    .neverStopPTag{
                        font-size: 18px;
                    }
                    .addedServices{
                        font-weight: 600;
                        color: #33A478;
                        margin-left: 155px;
                    }
                    .servicesImage{
                        width: 45%;
                        height: 53px;                    
                    }
                    .servicesList{
                        line-height: 60px;
                        text-align: left;
                    }
                    .businessServiceImage{
                        width: 100%;
                        margin-top: 4%;
                        height: 500px;
                    }
                    .hrBusinessdesign{
                        border: 0;
                        height: 1px;
                        background-image: linear-gradient(to right, rgba(0, 0, 0, 0), #33A478, rgba(0, 0, 0, 0));
                    }
                    .servicesHeading{
                        padding: 20px 27px 30px 144px;
                    }
                    @media only screen and (max-width: 600px){
                        .addedServices {
                            font-weight: 600;
                            color: #33A478;
                            margin-left: 0px;
                        }
                        .servicesList {
                            line-height: 60px;
                            text-align: center;
                        }
                        .businessServiceImage{
                            width: 100%;
                            margin-top: 0px;
                            height: auto;
                        }
                        .servicesHeading{
                            padding: 20px 30px;
                        }
                        .servicesImage{
                            width: 25%;
                            height: 53px;                    
                        }
                    }
                    @media only screen and (min-width: 768px) and  (max-width:1024px){
                        .addedServices {
                            font-weight: 600;
                            color: #33A478;
                            margin-left: 0px;
                        }
                        .servicesList {
                            line-height: 60px;
                            text-align: center;
                        }
                        .businessServiceImage{
                            width: 100%;
                            margin-top: 0px;
                            height: 500px;
                        }
                        .servicesHeading{
                            padding: 20px 20px;
                        }
                        .servicesImage{
                            width: 88%;
                            height: 53px;                    
                        }
                    }
                    `}
                </style>
                
                <div className="container-fluid" align="center" >
                    <h3 className="neverStopHeading">Never Stop Learning</h3>
                    <hr className="hrBusinessdesign"/>
                    <p className="neverStopPTag">With our impeccable methods and services of training we ensure that you never cease <strong>Learning</strong></p>
                    <div className="row">
                        <div className="col-md-5">
                            <h4 className="addedServices">Skillrary's value added services</h4>
                            <div className="row servicesHeading">
                                <div className="col-md-4">
                                    <img src={livetraining} className="servicesImage"/>
                                </div>
                                <div className="col-md-8">
                                    <h4 className="servicesList">Live & Self Paced classes</h4>
                                </div>
                                <div className="col-md-4">
                                    <img src={seotraining} className="servicesImage"/>
                                </div>
                                <div className="col-md-8">
                                    <h4 className="servicesList">Classroom Training</h4>
                                </div>
                                <div className="col-md-4">
                                    <img src={server} className="servicesImage"/>
                                </div>
                                <div className="col-md-8">
                                    <h4 className="servicesList">Cloud Labs</h4>
                                </div>
                                <div className="col-md-4">
                                    <img src={online} className="servicesImage"/>
                                </div>
                                <div className="col-md-8">
                                    <h4 className="servicesList">Online Compilers</h4>
                                </div>
                                <div className="col-md-4">
                                    <img src={analytics} className="servicesImage"/>
                                </div>
                                <div className="col-md-8">
                                    <h4 className="servicesList">Skills Analytics</h4>
                                </div>
                                <div className="col-md-4">
                                    <img src={development} className="servicesImage"/>
                                </div>
                                <div className="col-md-8">
                                    <h4 className="servicesList">Projects</h4>
                                </div>
                                <div className="col-md-4">
                                    <img src={taskList} className="servicesImage"/>
                                </div>
                                <div className="col-md-8">
                                    <h4 className="servicesList">Assignments</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="container">
                                <img src={neverstopLearning} className="businessServiceImage"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default neverStopLearning;